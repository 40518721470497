:root {
    --core-color: #151F6D ;
    --secondary--yellow: #EAAA00;
    --secondary--purple: #84329B;
    --secondary--green: #008C95;
    --secondary--blue: #00A9E0;
    --secondary--idontknowwhatthefuck: #99D6EA;
  }

.curser_pointer{
    cursor: pointer;
}

.danger_alert{
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    border-radius: 2px;
    height:"25";
    font-size: 15px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.success_alert{
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    border-radius: 2px;
    height:"25";
    font-size: 15px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.warning_alert{
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
    border-radius: 2px;
    height:"25";
    font-size: 15px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.dropdown_style{
    border: 1px solid #ccc !important;
    border-radius: 4px !important;
}

// .loading_div{
//     background-color:#EDEFF0;
//     -webkit-animation-name: example; /* Safari 4.0 - 8.0 */
//   -webkit-animation-duration: 4s; /* Safari 4.0 - 8.0 */
//   animation-name: example;
//   animation-duration: 4s;
//     padding-top: 30%;
//     padding-bottom: 30%;
// }

.loading_div{
    background-color:#EDEFF0;
    padding-top: 20%;
    padding-bottom: 20%;
    border-radius: 10px;
    animation: blinkingBackground 1.5s infinite;
}
@keyframes blinkingBackground{
    0%		{ background-color: #e5e6eb;}
    100%		{ background-color:white ;}
}

.loading_text{
    animation:blinkingText 1.5s infinite;
    padding-left: 5%;
}
@keyframes blinkingText{
    0%{     color: #000;    }
    49%{    color: transparent; }
    50%{    color: transparent; }
    99%{    color:transparent;  }
    100%{   color: #000;    }
}

.styles_modalCenter__L9F2w{
    border-radius: 8px !important;
}

.styles_modal__gNwvD{
    width: 60% !important;
}

.colourCheckbox{
    padding-right: 10px;
}

.colourCheckboxLabel{
    padding-top: 10px;
}

.ippf-logo { 
    max-width: 120px !important;
}

.uk-button-success {
    background-color: var(--secondary--blue);
}

.toolbar-nav > li > a:after {
    background-color: var(--secondary--yellow);
}

.uk-button-success:hover, .uk-button-success:focus {
    background-color: var(--secondary--yellow);
    border-color: var(--secondary--yellow);
}

.uk-circle{
    border-radius: 5px;
}
.adm-logo{
    background-color: #ffffff !important;
}