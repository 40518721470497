/* p { */
    /* font-family: "Adobe Arabic" !important; */
    /* direction:rtl !important; */
/* } */



.contactUsStyle {
  padding-top: 20px;
}
.IconSize {
  max-width: 15%;
}

.IppfLogo {
  padding-left: 10px !important;
  max-width: 156px !important;
}
.Grid-Inner-Contents {
  text-align: center !important;
}
/* .DivContent{
  text-align: center!important ;

} */
.text-center {
  text-align: center !important ;
  min-height: 100vh;
}

.align {
  vertical-align: initial;
}
.uk-nav-padding {
  padding: 15px 15px;
}

.App {
  text-align: center;
}

.app {
  min-height: 100vh;
}
.align-center {
  text-align: center !important;
}

.uk-subnav-pill li {
  padding-left: 15px;
}

.uk-subnav-pill {
  margin-top: -37px !important;
}

.uk-subnav-pill li a {
  background-image: url(./images/tab_back.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  text-align: center;
  min-width: 120px;
  font-size: 18px !important;
  font-weight: 500;
}

.uk-subnav-pill > .uk-active > a:before,
.uk-subnav-pill > .uk-active > a:after {
  display: none !important;
}

.uk-subnav-pill li.uk-active a {
  background-image: url(./images/tab_back_a.png);
  border-radius: 0;
  background-color: transparent !important;
}

.uk-slidenav-previous {
  margin-left: 10%;
}

.uk-slidenav-next {
  margin-right: 10%;
}

.overlay {
  position: absolute;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  top: 0;
}

.header_wrapper {
  z-index: 10;
  width: 100%;
}

.button.disabled {
  opacity: 0.65;
  cursor: not-allowed;
}

.button.disabled:hover {
  background-color: #f5f5f5;
}

.container-size {
  height: 200px;
}

.background_head {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner_wraper {
  position: relative;
}

.align-center ul {
  width: 70px;
  height: auto;
  display: inline-block;
  padding-left: 10%;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.img-size {
  width: 367px !important;
  height: 190px !important;
}
.uk-transition-opaque {
  opacity: 1;
}

.float {
  float: right !important;
}

.Login_text {
  color: white !important;
}

/* .buttonBorder{
  border: solid 1px white !important;
} */

.loginTitle {
  color: white;
  font-size: 22px;
  /* padding-top: 10px; */
}

.containerStyle {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  text-align: justify;
  color: #777777;
  font-weight: 400;
  /* padding-top: 35px; */
  /* line-height: 1.6; */
  line-height: 1.4285em;

  font-size: 17px;
}

.contactStyle {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  color: #777777;
  font-weight: 400;
  line-height: 1.6;
  font-size: 17px;
}

.fontstyle {
  background: #edeff0;
  font-family: proxima, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

.fontFamily {
  font-family: proxima, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

.hero-bg {
  margin-top: -90px;
}

.hero-bg2 {
  margin-top: -65px;
  padding: 18px 40px;
  padding-top: 80px;
  border-radius: 0 0 30px 0;
}

.ContactTittle {
  color: #008c95;
  line-height: 2.5em;
}

@media (max-width: 960px) {
  .login_side_order {
    -ms-flex-order: 1;
    order: 1;
  }
}

.Main-banner-login-sec {
  background-color: #00a9e0;
  /* border: solid 1px; */
  border-radius: 10px;
}

.Main-full-banner {
  margin-bottom: 40px;
}

@media (max-width: 960px) {
  .banner_side_order {
    -ms-flex-order: 1;
    order: 2;
  }
}

.login_container {
  /* margin-top: 150px !important;
   */
  padding-top: 60px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 20px;
}

@media (max-width: 960px) {
  .impact_container {
    padding: 30px;
  }
}

.Banner_text_shadow {
  text-shadow: 2px 3px 5px #000000b8;
}

.resourceContainer {
  width: 80%;
  float: left;
  margin: 40px;
}

.resourceContainerHeader {
  background: #ff6852;
  padding: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: white;
}

.resourceContainerListElement {
  background: #e2cae8;
}

.footerCopyright {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  margin-top: 30px !important;
  color: #ffffff;
  background-color: #008c95 !important;
}

.foot {
  padding-bottom: 0px !important;
}

.copyRight {
  padding-top: 6px !important;
}

.copyRightText {
  padding-left: 70px !important;
  padding-top: 6px !important;
}
.copyRightText_spanish {
  padding-left: 70px !important;
  font-size: 12px !important;
  padding-top: 6px !important;
}
.image img {
  width: 36%;
}
.container {
  position: relative;
  width: 100%;
  max-width: 400px;
}

/* .image {
  display: block;
  width: 100%;
  height: auto;
} */

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.3s ease;
  background-color: #edeff0;
}

.container:hover .overlay {
  opacity: 1;
  opacity: 0.6;
  filter: alpha(opacity=60); /* For IE8 and earlier */
}

.icon {
  color: white;
  font-size: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.fa-user:hover {
  color: #eee;
}

.imageSize {
  width: 320px !important;
}

.paddingHeader {
  /* padding: 0px !important; */
  padding: 0px 15px !important;
}

.bo {
  border: 1px solid #e2e2e2 !important;
}

.ll {
  border: 1px solid rgba(34, 36, 38, 0.15);
  background-color: #ffff !important;
}
.semanticFontOnly {
  font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif !important;
}

.iconSize {
  width: 56% !important;
}

.back {
  background: url("./assets/images/Contact_us.jpg") !important;
  background-repeat: no-repeat !important;
  background-size: 100% !important;
}

/* html{
  font-size: 17px !important;
} */

/* .inherit{
font-family: proxima, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
font-size: 16px !important;

}
/*

.inherit2{
  font-family:Lato,'Helvetica Neue',Arial,sans-serif !important;
  font-size: 22px !important;
} */

.semantic-font {
  padding: 0;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.alreadyReg {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
}
.lineHeight {
  line-height: 19.999px !important;
}
.margin {
  margin-top: 1px !important;
}

.box_sizing {
  box-sizing: border-box !important;
  color: rgba(191, 191, 191, 0.87);
  font-weight: 800;
}

.placholdercolor {
  /* color: rgba(191,191,191,.87); */
  color: #c8c8c8;
}
.uk-inline label {
  color: #333;
  /* font-size: .875rem; */
  /* margin-top: 13px; */
  /* margin-bottom: 8px; */
}

.positionabsolute {
  position: absolute;
  /* width: 50%; */
  bottom: 10px;
  /* border: 3px solid #8AC007; */
}

.label input[type="radio"] {
  color: rgba(0, 0, 0, 0.87) !important;
}

.dropdownnn {
  cursor: pointer;
  word-wrap: break-word;
  line-height: 1em;
  white-space: normal;
  outline: 0;
  transform: rotateZ(0);
  min-width: 14em;
  min-height: 2.71428571em;
  background: #fff;
  display: inline-block;
  padding: 0.78571429em 2.1em 0.78571429em 1em;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: none;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  transition: box-shadow 0.1s ease, width 0.1s ease;
}

.linkEffectHover a:visited {
  text-decoration: none;
}

.linkEffectHover a:hover span.hoverlink {
  text-decoration: underline !important;
}

.linkEffectHover a:active {
  text-decoration: underline !important;
}
.HeaderBorder {
  background-color: #00a9e0 !important ;
}

.HeaderBorder h4 {
  color: white !important;
  font-size: 18px !important;
  margin-bottom: 0 !important;
  border: none;
  /* margin: calc(2rem - .14286em) 0 1rem; */
  padding: 0;
  font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
  /* font-weight: 700; */
  /* line-height: 1.28571429em; */
  /* text-transform: none; */
}

.gridstyle {
  background-color: #f0f6fa !important;
  width: 100% !important;
  padding: 30px;
  margin-bottom: 40px !important;
  box-sizing: border-box !important;
}

.titleFaq {
  display: flex;
  position: relative;
  padding-right: 2em;
}

.titleFaq:after {
  position: absolute;
  right: 0;
}

.titleFaq strong {
  display: table-cell;
  padding-right: 5px;
}

.Rowcommon {
  display: flex;
  padding-right: 2em;

}
.Rowcommon div{
  padding-right: 5px;
}

.titleFaq img{
  max-width: none;
  max-height:none;
  object-fit: cover;
}
.Rowcommon img{
  max-width: none;
  max-height:none;
  object-fit: cover;
}
.firstColumn {
  position: relative;
  display: inline-block;
  /* padding-left: 1rem; */
  /* padding-right: 1rem; */
  vertical-align: top;
  margin-top: 0;
  margin-bottom: 0;
  width: 3.25% !important;
}

.firstColumn img {
  width: 100%;
  height: auto;
  box-sizing: border-box;
}

.secondColumn {
  width: 93.75% !important ;
  padding-bottom: 20px;
  padding-left: 20px;
  position: relative;
  display: inline-block;
  /* padding-left: 1rem; */
  padding-right: 1rem;
  vertical-align: top;
  margin-top: 0;
  margin-bottom: 0;
}

.ui.segment {
  position: relative !important;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  margin: 1rem 0;
  padding: 1em;
  border-radius: 0.28571429rem;
  border: 1px solid rgba(34, 36, 38, 0.15);
  font-size: 1rem;
}

@media only screen and (max-width: 1199px) and (min-width: 992px) {
}
.uiContainer {
  max-width: 1199px;
  min-width: 992px;
  width: 1130px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  display: block;
  max-width: 100% !important;
}

.formsegment {
  position: relative;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  /* margin: 1rem 0; */
  margin-bottom: 3% !important;
  padding: 1em 1em;
  border-radius: 0.28571429rem;
  border: 1px solid rgba(34, 36, 38, 0.15);
  font-family: proxima, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield !important;
}

p {
  line-height: 1.4285em;
}

.buttonSignin {
  font-size: 1rem;
  display: inline-block;
  min-height: 1em;
  outline: 0;
  border: none;
  vertical-align: baseline;
  background: #e0e1e2 none;
  /* color: rgba(0,0,0,.6); */
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  margin: 0 0.25em 0 0;
  padding: 0.78571429em 1.5em 0.78571429em;
  text-transform: none;
  /* text-shadow: none; */
  font-weight: 700;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  border-radius: 0.28571429rem;
  /* box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(34,36,38,.15) inset; */
  -webkit-user-select: none;
  user-select: none;
  transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease,
    box-shadow 0.1s ease, background 0.1s ease;
  will-change: "";
  -webkit-tap-highlight-color: transparent;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #c8c8c8 !important;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #c8c8c8;
  /* opacity: 1 !; */
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #c8c8c8;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #c8c8c8;
}
/* input:focus::placeholder{

} */

.adminlogooo {
  /* display: block; */
  background-color: rgb(255, 255, 255);
  padding: 10px 0px;  
  width: 280px !important;
  margin: 0px auto !important;
  
}

.adminContant {
  min-height: 100vh;
}

.clicked:hover i,
.clicked:focus i {
  color: #1e87f0;
}

.Container .resourceContainer {
  max-width: 300px;
}

.miniuteText {
  font-size: 12px;
}

.timeButton {
  padding-top: 4px;
  padding-bottom: 4px;
}

.fas[data-count] {
  position: relative;
}

.smallerCount[data-count]:after {
  position: absolute;
  right: 2.3px;
  top: 4px;
  content: attr(data-count);
  padding: 8px;
  /* border-radius: 10em; */
  /* line-height: .9em; */
  color: #000;
  /* background: rgba(255,0,0,.75); */
  text-align: center;
  /* min-width: 2em; */
  font: bold 14px sans-serif;
}

.greaterCount[data-count]::after{
  position: absolute;
  right: 1.7px;
  /* right: 2.0px; */
  top: 4px;
  content: attr(data-count);
  padding: 8px;
  padding-right: 5px !important;
  color: #000;
  text-align: center;
  font: bold 14px sans-serif;
}

.ck-style * + h5,
* + .uk-h5 {
  margin-top: 0;
}

.ck-style .image img {
  width: 100%;
  margin-left: auto !important;
  margin-right: auto !important;
}
/* //changed the size from 40% on april 19 */
.ck-style .image {
  width: 50%;
  /* display: flex; */
  margin-left: auto;
  margin-right: auto;
}
.ck-style .image figcaption{
  text-align: center;
}
/* uncomment this, if the image size need to be increased */
/* .ck-style .image img {
  width: 50%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
} */
/* ////size to be increase above */
.ck-style .image-style-align-left {
  float: left;
  margin-right: var(--ck-image-style-spacing);
}

.ck-style .image-style-align-right {
  float: right;
  margin-left: var(--ck-image-style-spacing);
}

.ck-style .image-style-align-left img{
  /* margin: 0 auto !important; */
  display: flex;
}

.ck-style .image-style-align-right img{
  /* margin: 0 auto !important; */
  display: flex;
}

.ck-style .image-style-align-left figcaption{
  text-align: center;
}

.ck-style .image-style-align-right figcaption{
  text-align: center;
}

.ck-style .image-style-align-center,
.ck-style .image-style-align-left,
.ck-style .image-style-align-right,
.ck-style .image-style-side {
  max-width: 50%;
}

.ck-style.image-style-align-center {
  margin-left: auto;
  margin-right: auto;
  max-width: 50%;
}

.ck-style .image-style-side {
  max-width: 50%;
  float: right;
  margin-left: var(--ck-image-style-spacing);
}

.faq-accordion .uk-accordion-title:hover,
.faq-accordion .uk-accordion-title:focus {
  color: #666;
}

 .ck-style .image.image_resized {
  max-width: 100%;
  display: block;
  box-sizing: border-box;
}

.ck-style .image.image_resized img {
  width: 100%;
}

.ck-style .image.image_resized > figcaption {
  display: block;
}

.ck-style .table {
  margin: 1em auto;
  display: table;
}

.ck-style .table table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  border: 1px double hsl(0, 0%, 70%);
}

.ck-style .table table td,
.ck-style .table table th {
  min-width: 2em;
  padding: 0.4em;
  border-color: hsl(0, 0%, 85%);
  border: 1px solid hsl(0, 0%, 70%);
  vertical-align: top;
}

.ck-style .table table th {
  font-weight: bold;
  background: hsl(0, 0%, 98%);
}

.ck-style .media {
  clear: both;
  margin: 1em 0;
  display: block;
  min-width: 15em;
} 

.navBarSize {
  width: 285px !important;
}

.navBarSubDiv {
  overflow: auto;
  height: 120%;
}

.navBarSubDiv::-webkit-scrollbar {
  width: 5px;
  height: 4px;
  background-color: #00A9E0;
  border-radius: 5px !important;
}

.navBarSubDiv::-webkit-scrollbar-track {
  background-color: #00A9E0;
}

.navBarSubDiv::-webkit-scrollbar-thumb {
  background-color: #b8b8b898;
}

.navBarSubDiv::-webkit-scrollbar-thumb:hover {
  background-color: #888888;
}

.faq-question-icon,
.faq-answer-icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  display: table-cell;
}

.ck.ck-list {
  max-height: 300px;
  overflow-y: scroll;
}

.ck-font-size-dropdown .ck.ck-list__item:last-child {
  padding-top: 10px;
  padding-bottom: 10px;
}

.ck-font-size-dropdown .ck.ck-list__item:last-child .ck-button {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.main-container {
  position: relative;
  width: 400px;
  top: 100px;
  /* bottom: 0;
  left: 0;
  right: 0; */
}

.form-container {
  /* bottom: 100px; */
}

legend.form-label {
  font-size: 1.5rem;
  color: #3d658f;
}

.control-label {
  font-size: 0.8rem;
  font-weight: bold;
  color: #52657a;
}

.form-control {
  font-size: 1rem;
}

.form-hint {
  font-size: 0.6rem;
  line-height: 1.4;
  margin: -5px auto 5px;
  color: #999;
}
.form-hint.error {
  color: #c00;
  font-size: 0.8rem;
}

button.btn {
  letter-spacing: 1px;
  font-size: 0.8rem;
  font-weight: 600;
}

.password-count {
  bottom: 16px;
  right: 10px;
  font-size: 1rem;
}

.strength-meter {
  position: relative;
  height: 3px;
  background: #ddd;
  margin: 7px 0;
  border-radius: 2px;
}
.strength-meter:before,
.strength-meter:after {
  content: "";
  height: inherit;
  background: transparent;
  display: block;
  border-color: #fff;
  border-style: solid;
  border-width: 0 6px 0;
  position: absolute;
  width: calc(20% + 6px);
  z-index: 10;
}
.strength-meter:before {
  left: calc(20% - 3px);
}
.strength-meter:after {
  right: calc(20% - 3px);
}

.strength-meter-fill {
  background: transparent;
  height: inherit;
  position: absolute;
  width: 0;
  border-radius: inherit;
  transition: width 0.5s ease-in-out, background 0.25s;
}

.strength-meter-fill[data-strength="0"] {
  width: 20%;
  background: darkred;
}
.strength-meter-fill[data-strength="1"] {
  width: 40%;
  background: orangered;
}
.strength-meter-fill[data-strength="2"] {
  width: 60%;
  background: orange;
}
.strength-meter-fill[data-strength="3"] {
  width: 80%;
  background: yellowgreen;
}
.strength-meter-fill[data-strength="4"] {
  width: 100%;
  background: green;
}

.testRegister {
  height: 40px !important;
  width: 98%;
  /* line-height: 19.999px !important;
    border: 1px solid rgba(34,36,38,.15);
    background-color: #ffff !important;
    border-radius: 4px; */
}

.newsButton {
  /* padding:5px; */
  margin: 10px;
  border-radius: 25px;
  width: 160px;
  background-color: #ffffff !important ;
  color: #3e474f;
}


.newsCategoryButton {
  margin-right: 15px;
  border-radius: 25px;
  border: solid 1px rgba(167, 167, 167, 0.692);
  background-color: #ffffff00;
  color: #161616;
  display: inline-block;
  min-width: 158px;
  text-align: center;
  padding: 0px;
  font-weight: 700;
  line-height: 0px;
  font-family: Lato,Helvetica Neue,Arial,Helvetica,sans-serif;
  height: 35px;
}

.newsCategoryButtonActive {
  margin-right: 15px;
  border-radius: 25px;
  border: solid 1px rgba(167, 167, 167, 0.692);
  background-color: #1eabe0;
  color: rgb(255, 255, 255);
  display: inline-block;
  min-width: 158px;
  /* padding: 2px 20px !important; */
  padding: 0px;
  font-weight: 700;
  line-height: 0px;

  font-family: Lato,Helvetica Neue,Arial,Helvetica,sans-serif;
  height: 35px;


}

/* .newsButton:focus{
  background-color: #1EABE0 !important;
  color:#ffffff
} */

.selected {
  border: 2px solid rgb(0, 0, 255);
}

.ex2 {
  padding-left: 0;
}

.ex2 a {
  margin-right: 15px;
  border-radius: 25px;
  border: #00000033 solid 1px;
  background-color: inherit;
  color: #000000;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  min-width: 100px;
  text-align: center;
  padding: 2px 20px;
}

.main-nav-active {
  /* color: tomato !important; */
  background-color: #1eabe0 !important;
  color: #ffffff !important;
}

.ex2 a:first-child {
  margin-left: 0;
}

.ex2 a:hover {
  color: #1eabe0;
}

.ex2 a:active {
  color: #ffffff !important;
  background-color: #1eabe0 !important;
}

/* Style the active class, and buttons on mouse-over */

.ex2 a:focus {
  color: #ffffff !important;
  background-color: #1eabe0 !important;
}

/* .ex2 a:active {
  color: #1e87f0;
} */

.deleteUser {
  background-color: #f05050 !important;
  color: #ffffff;
}

.deleteUser:hover {
  background-color: #ff3333 !important;
}

.ModalButton button {
  margin-right: 10px;
}

.table.moduleTable {
  width: 100% !important;
  /* height:260px; */
  padding-bottom: 15px;
  border-spacing: 0 !important;
}

.moduleContainerTable {
  max-height: 350px;
  border-radius: 5px;
}

.moduleTitle td {
  padding-left: 60px;
  background-color: #1e87f0;
  height: 60px;
  color: #fff;
  font-size: 20px;
  font-weight: 550;
  border-radius: 3px;
  margin: 0px;
}

.moduleTitle_Ar td{
  padding-right: 60px !important;
  background-color: #1e87f0;
  height: 60px;
  color: #fff;
  font-size: 20px;
  font-weight: 550;
  border-radius: 3px;
  margin: 0px;
}
.moduleLearning td {
  padding-left: 60px;
  font-weight: bold;
  color: #585858;
  font-size: 18px;
}
.moduleLearning_Ar td{
  padding-right: 60px;
  font-weight: bold;
  color: #585858;
  font-size: 18px;
}
.records_visibility{
  visibility: visible !important;
}

.moduleLearningSum td {
  padding-left: 45px;
  /* width:60% !important; */
}

.moduleLearningObjective td ul {
  /* list-style-type: circle; */
  margin: 0px;
}

.LearningCell {
  background-color: #1e87f0;
  border-radius: 3px;
  color: #fff;
}

.gridLearningSum {
  width: 50%;
  height: 22px;
  background-color: #1e87f0;
  color: #fff;
  border-radius: 3px;
  padding-left: 15px;
  margin-bottom: 3px;
  padding-top: 5px;
  padding-bottom: 9px;
  font-size: 18px;
  /* border: 1px solid #F58021; */
}
.gridLearningSum_Ar{
  width: 50%;
  height: 22px;
  background-color: #1e87f0;
  color: #fff;
  border-radius: 3px;
  padding-right: 15px;
  margin-bottom: 3px;
  padding-top: 5px;
  padding-bottom: 9px;
  font-size: 18px;
}
.ModuleSummary {
  background-color: #1e87f0;
  width: 31%;
  height: 50px;
  color: #fff;
  font-size: 26px;
  font-weight: bold;
  border-bottom-right-radius: 65px;
  background-color: #f58021;
  /* padding:0px !important; */
  padding-left: 32px;
  /* padding-top:9px; */
}
.ModuleSummary_Ar {
  background-color: #1e87f0;
  width: 31%;
  height: 50px;
  color: #fff;
  font-size: 26px;
  font-weight: bold;
  border-bottom-left-radius: 65px;
  background-color: #f58021;
  padding-right: 32px;
}
.table.muduleSummary {
  border: 2px solid #f58021;
}

.marginsPara {
  margin: 0px;
  padding-bottom: 5px;
}

.marginsPara2 {
  margin: 0px;
}

.formLanguageSwitcher:hover {
  color: white;
  background-color: #292929;
  border-radius: 5px;
}

.formLanguageSwitcher {
  font-size: 0.9rem;
  cursor: pointer;
  padding: 0.4rem 1rem;
  font-weight: 500;
  margin: 0rem 0.2rem;
}

.formLanguageSwitcherActive {
  font-size: 0.95rem;
  cursor: pointer;
  color: white;
  background-color: black;
  border-radius: 5px;
  padding: 0.4rem 1rem;
  font-weight: 500;
  margin: 0rem 0.2rem;
}

.formLanguageSwitcher:focus {
  color: #00a9e0 !important;
}

.formLanguageSwitcherDiv {
  width: fit-content;
  float: right;
}

.contentUnavailableMessage {
  margin: 0 auto;
  width: fit-content;
  height: 3rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.messageSubDiv {
  float: left;
  width: fit-content;
  margin-left: 0.5rem;
}

.messageInfoIcon {
  height: 1.5rem;
  width: 1.5rem;
  float: left;
}

.uk-card-body h4:last-child{
  margin-top: 25px !important;
}

.summary_list {
  width: 95%;
  margin-top: 0.8rem;
}
.summary_list_Index {
  float: left;
  margin-right: 1rem;
  margin-top: 0.9rem;
}
.summary_listRemoveIcon{
    margin-bottom: -10px;
    margin-left: 20px;
}
.summary_listRemove {
  margin-top: 0.6rem;
  margin-left: 3rem;
  margin-right: 0.2rem;
}
.squareIcon {
  height: 0.8rem;
  width: auto;
  position: absolute;
  margin-left: -1.2rem;
  margin-top: 0.36rem;  
}
.squareIcon_Ar{
  height: 0.8rem;
  width: auto;
  position: absolute;
  margin-right: -1.2rem;
  margin-top: 0.36rem; 
}
.learningSummary td ul {
  padding-left: 50px;
  width: 95%;
}


.learningSummary td ul li, dd {
  font-size: 15px;
  font-weight: 500;
  color: #000000;
  /* width: 95%; */
  /* width: 100%; */

  /* list-style-image: url("./assets/images/EllipseOrange.png") ; */
  list-style-image: url("./assets/images/Ellipsev5.png");
  padding-left: 4px;
}

.learningSummary td ul li span {
  position: relative;
  /* left: 5px; */
  padding-bottom: 5px;
}
.learningSummary td ul li span p {
  margin: 5px;
  width: 100%;
  /* height: 21px !important; */
}
/* //start arabic summary */

.learningSummary_Ar td ul {
  padding-right: 50px;
  width: 95%;
}

.learningSummary_Ar td ul li, dd {
  font-size: 15px;
  font-weight: 500;
  color: #000000;
  /* width: 95%; */
  /* list-style-image: url("./assets/images/EllipseOrange.png") ; */
  list-style-image: url("./assets/images/Ellipsev5.png");
  padding-right: 4px;
}

.learningSummary_Ar td ul li span {
  position: relative;
  left: 5px;
  padding-bottom: 5px;
}
.learningSummary_Ar td ul li span p {
  margin: 5px;
  width: 100%;
}

/* //endsummary arabic */

.moduleLearningObjective td ul.experiences li {
  color: #585858;
  font-weight: 500;
  padding-left: 70px;
  list-style: none;
  /* margin-bottom: 2.5em; */
  font-size: 16px;
  line-height: fixed;
}

.moduleLearningObjective td ul.experiences li {
  /* border-top: 1px solid black; */

  position: relative; /* so that pseudoelements are positioned relatively to their "li"s*/
  margin-bottom: 0;
}

.moduleLearningObjective td ul.experiences li:after {
  /* bullets */
  content: url("./assets/images/circledBullet.png");
  position: absolute;
  left: -26px;
  top: 0px;
  padding-left: 53px;
  padding-top: 5px;
}

.moduleLearningObjective td ul.experiences li:before {
  /* lines */
  content: "";
  position: absolute;
  left: -16px; /* adjust manually */
  border-left: 1px solid #1e87f0;
  height: 100%;
  width: 1px;
  margin-left: 50px !important;
  /* padding-bottom: 0px; */
}

.moduleLearningObjective td ul.experiences li:first-child:before {
  bottom: 11.2px;
}

.moduleLearningObjective td ul.experiences li:last-child:before {
  height: 18px;
}

/* Arabic learning objective style */
.moduleLearningObjective_Ar td ul {
  margin: 0px;
}
.moduleLearningObjective_Ar td ul.experiences li {
  color: #585858;
  font-weight: 500;
  padding-right: 70px;
  list-style: none;
  font-size: 16px;
  line-height: fixed;
}

.moduleLearningObjective_Ar td ul.experiences li {
  position: relative; /* so that pseudoelements are positioned relatively to their "li"s*/
  margin-bottom: 0;
}

.moduleLearningObjective_Ar td ul.experiences li:after {
  /* bullets */
  content: url("./assets/images/circledBullet.png");
  position: absolute;
  right: -26px;
  top: 0px;
  padding-right: 53px;
  padding-top: 5px;
}

.moduleLearningObjective_Ar td ul.experiences li:before {
  /* lines */
  content: "";
  position: absolute;
  right: -16px; /* adjust manually */
  border-left: 1px solid #1e87f0;
  height: 100%;
  width: 1px;
  margin-right: 50px !important;
}

.moduleLearningObjective_Ar td ul.experiences li:first-child:before {
  bottom: 11.2px;
}

.moduleLearningObjective_Ar td ul.experiences li:last-child:before {
  height: 18px;
}
/* Arabic learning objective end */

.learningSummary td ul.experiences li {
  color: #585858;
  font-weight: 500;
  padding-left: 50px;
  list-style: none;
  /* margin-bottom: 2.5em; */
  font-size: 16px;
  line-height: fixed;
}

.learningSummary td ul.experiences li {
  /* border-top: 1px solid black; */

  position: relative; /* so that pseudoelements are positioned relatively to their "li"s*/
  margin-bottom: 0;
}

.learningSummary td ul.experiences li:after {
  /* bullets */
  content: url("./assets/images/EllipseOrange.png");
  position: absolute;
  left: -26px;
  top: 0px;
  padding-left: 33px;
  padding-top: 5px;
}

.learningSummary td ul.experiences li:before {
  /* lines */
  content: "";
  position: absolute;
  left: -16px; /* adjust manually */
  border-left: 1px solid #f58021;
  height: 100%;
  width: 1px;
  padding-bottom: 10px;
  margin-left: 50px !important;
  /* padding-bottom: 0px; */
}

/* start arabic */

.learningSummary_Ar td ul.experiences li {
  color: #585858;
  font-weight: 500;
  padding-right: 50px;
  list-style: none;
  /* margin-bottom: 2.5em; */
  font-size: 16px;
  line-height: fixed;
}

.learningSummary_Ar td ul.experiences li {
  /* border-top: 1px solid black; */

  position: relative; /* so that pseudoelements are positioned relatively to their "li"s*/
  margin-bottom: 0;
}

.learningSummary_Ar td ul.experiences li:after {
  /* bullets */
  content: url("./assets/images/EllipseOrange.png");
  position: absolute;
  left: -26px;
  top: 0px;
  padding-right: 33px;
  padding-top: 5px;
}

.learningSummary_Ar td ul.experiences li:before {
  /* lines */
  content: "";
  position: absolute;
  left: -16px; /* adjust manually */
  border-left: 1px solid #f58021;
  height: 100%;
  width: 1px;
  padding-bottom: 10px;
  margin-right: 50px !important;
  /* padding-bottom: 0px; */
}

/* end arabic */
.slideFooterStyle{
  display: flex !important;
  width: 600px;
  padding-left: 230px;
}

.slideFooterStyle p{
  color: #ffffff;
}

.slideFooterStyle button {
  margin: 5px;
  color: #ffffff;
  background-color: #323232;
  padding-top: 10px;
}

.slideFooterStyle .prev:hover{
  color: lightslategrey;
}
.slideFooterStyle .next:hover{
  color: lightslategrey;
}

.ZoomInit{
  margin-left: 50px ;
  padding-top: 5px;
  padding-left: 40px;
}
.ZoomInit .uk-button {
  padding-left: 0px;
  padding-right: 0px;
}

.zoom {
  bottom: 190px;
}

.zoom:hover {
color: lightslategrey;
}

.zoom-out {
  /* bottom: 120px; */
}

.zoom-out:hover {
  color: lightslategrey;
}

.zoom-init {
  /* bottom: 50px; */
}
.zoom-init:hover {
  color: lightslategrey !important;
}
.slideFoot{
  background-color: #323232;
  width: 70%;
}
.slideFoot nav{
  /* padding-left: 230px; */
}
.ZoomStyle{
  margin-left: 35px;
}
.ZoomStyle .uk-button{
  padding:0px ;
  padding-top: 14px;
}

.ZoomStyle .uk-button.zoom-init{
  /* width: 8% !important; */
}
.ZoomStyle a{
  color: #ffffff;
  padding:0px ;
  padding-top: 14px;
  padding-left: 5px !important;
  padding-bottom: 5px !important;
}

.ck-media__wrapper{

}

.pinIcon{
  height: 1.6rem;
  width: auto;
  padding-bottom: 4px;
  margin-left: 15px;
  float: right;
}

.pinIcon:hover{
  transform: scale(1.3);
  cursor: pointer;
}

.pinIcon_user{
  height: 1.7rem;
  width: auto;
  padding-bottom: 2px;
  margin-left: 15px;
  float: right;
}

.system_msg_style{
  margin-bottom: 1px !important;
  /* margin-top:5px !important */
}


element.style {
  width: 0px;
}
i.red.icon {
  color: #db2828!important;
}
i.icon, i.icons {
  font-size: 1em;
}

i.icon_asterisk {
  display: inline-block;
  opacity: 1;
  margin: 0 .25rem 0 0;
  height: 1em;
  font-family: Icons;
  font-style: normal;
  font-weight: 400;
  text-decoration: inherit;
  text-align: center;
}

/* .fixed_foot{
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
} */

.titlebottom_mar{
  margin-bottom: 4px !important;
}
.deleteModalDiv {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.589) !important;
  z-index: 1000;
  overflow: hidden !important;
}

.deleteModal {
  background-color: rgb(255, 255, 255) !important;
  margin: 0 auto !important;
  margin-top: 8% !important;
  padding: 1.6rem;
  border-radius: 5px;
  width: 40%;
  text-align: center;
  font-weight: 500;
}

.cancelDeleteUserButton {
  background-color: #e0e1e2;
  color: rgba(0,0,0,.6);
  width: 30%;
  margin: 10px;
  padding: 2px;
}

.cancelDeleteUserButton:hover{
  background-color: #cacbcd;
  color: rgba(0,0,0,.6);
  border-color: #e0e1e2;
}

.sumbitDeleteUserButton {
  background-color: #d12424;
  width: 30%;
  color: white;
  margin: 10px ;
  padding: 2px;
}

.sumbitDeleteUserButton:hover {
  background-color: #961a1a;
  color: white;
}

.deleteUser_buttons{
  margin-left:45%;
}

div.ui.inline.dropdown.languageClass div.text {
  font-weight: bold !important;
}

.learningSummary td ul.experiences li:first-child:before {
  bottom: 11.2px;
}

.learningSummary td ul.experiences li:last-child:before {
  height: 18px;
}
.learningSummary_Ar td ul.experiences li:first-child:before {
  bottom: 11.2px;
}

.learningSummary_Ar td ul.experiences li:last-child:before {
  height: 18px;
}
.presentationStyles {
  text-align: center;
  margin-bottom: 15px;
}
.react-pdf__Page{
 width: 600px !important;
 height: 515px !important;
 position: absolute;
 left: 0px;
 top: 0px;
 z-index: -1;
}

.react-pdf__Page__canvas{
  /* overflow: scroll; */
  /* height: 482px !important; */
  /* min-height: 450px !important; */
  /* min-height: 60vh ! important;
  max-width: 100vw;
  width: 500px !important; */

  /* max-height: 611px;
  max-width: auto; */

}
.DocStyle::-webkit-scrollbar {
  display: none ;
 }


.DocStyle{
  width: 70%;
  height: 550px;

  /* width: 720px in pixel; */
  /* height: 30%; */
  overflow: scroll;
  /* overflow-y: none; */
  -ms-overflow-style: none; 
  scrollbar-width: none ; 
}

/* .DocStyle{
  -ms-overflow-style: none; 
  scrollbar-width: none ; 
 } */

.DocStyle canvas{
  max-width: none;
  max-height:none;
  object-fit: cover;
} 

.DocStyle .ui table {
  padding-left: 177px;
}

.pdfRemoveIcon {
  height: 1.3rem;
  width: auto;
  position: absolute;
  margin-left: -1.4rem;
  margin-top: -0.2rem;
}

.pdfRemoveIcon:hover {
  transform: scale(1.1);
  cursor: pointer;
}

div.ui.inline.dropdown.languageSwitcher {
  font-size: 0.9rem;
  margin-right: 0.5rem;
}

div.ui.inline.dropdown.languageSwitcher div.text {
  font-size: 18px !important;
  font-family: proxima;
  /* color:#000000 !important; */
  /* color: #db3700 !important; */
  color: #ffffff !important;

}

div.ui.inline.dropdown.languageSwitcher div.visible.menu.transition {
  margin-left: -0.6rem;
  margin-top: 0.5rem;
  background-color: rgba(255, 255, 255, 0.849);
}

div.ui.inline.dropdown.languageSwitcher div.item {
  font-size: 0.9rem;
  padding: 0.7rem !important;
  text-align: left;
}

div.ui.inline.dropdown.languageSwitcher div.text {
  font-size: 0.9rem;
  color: white;
  font-weight: 400;
}

div.ui.inline.dropdown.languageSwitcher i.dropdown.icon::before {
  display: none;
}

/* 
div.ui.inline.dropdown.languageSwitcherLogin {
  font-size: 0.9rem;
  margin-right: 0.5rem;
}
*/
div.ui.inline.dropdown.languageSwitcherLogin div.visible.menu.transition {
  margin-left: -0.6rem;
  margin-top: 0.9rem;
  background-color: rgba(255, 255, 255, 0.849);
}

div.ui.dropdown.languageSwitcherLogin div.item {
  font-size: 0.9rem;
  padding: 0.7rem !important;
  text-align: center;
}

div.ui.dropdown.languageSwitcherLogin div.text {
  font-size: 1rem;
  font-weight: bold !important;
  color: black;
  font-weight: 400;
  text-align: center;
  /* margin-top: -1.5rem !important; */
  font-family: proxima;
}

div.ui.dropdown.languageSwitcherLogin {
  text-align: center;
  /* width: 1rem !important; */
  position: absolute;
  /* top: 0; */
  margin-left: -4.5rem !important;
  margin-top: -0.1rem !important;
  /* background-color: khaki; */
  /* margin-bottom: -1rem !important; */
}


div.ui.dropdown.languageSwitcherLogin i.dropdown.icon::before {
  display: none !important;
}

.langDropdownIcon {
  color: black;
  margin-left: -1rem;
  margin-top: -2rem;
  height: 0.2rem;
  width: 0.2rem;
  font-size: 0.8rem;
  /* position: absolute; */
  display: none;
}

img.languageIcon {
  height: 1.7rem;
  width: auto;
  margin-right: 0.4rem;
}

/* Flash Quiz Styles */

.flashQuizMainDiv {
  width: 100%;
  /* background-color: #a7f19d; */
  color: #000;
  padding: 1rem 1rem 3rem 1rem;
  margin-bottom: 3rem !important;
}

.flashQuizHeading {
  font-weight: bold;
  font-size: 1.1rem;
}

.flashQuizHr {
  margin: 1.5rem 0rem;
}

.flashQuizQuestionDiv {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  background-color: #f3f3f3e0;
  padding: 0.6rem;
}

.flashQuizQuestionDivDisabled {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  background-color: #f3f3f3e0;
  padding: 0.6rem;
  color: rgb(133, 133, 133)
}

.flashQuizAnswerDiv {
  margin-top: 0.4rem;
  margin-left: 0.7rem;
  display: block;
  margin-left: -4px;
}

.flashQuizAnswerDiv_after_submission{
  margin-top: 0.4rem;
  margin-left: 0.7rem;
  margin-left: 1.2rem;
}

.flashQuizSubmitButton {
  float: right;
  margin: 0rem;
  margin-bottom: 1rem;
  background-color: rgb(187, 187, 187);
  font-size: 0.9rem;
  width: fit-content !important;
}

.flashQuizSubmitButton:hover {
  color: white;
  background-color: #0f7ae5;
}

/* Add Sub Module */
.flashQuizQuestionButton {
  margin-top: 0rem !important;
}

.subModuleQuestionNumber {
  float: left;
  margin-right: 1rem;
  margin-top: 0.1rem;
}

.subModuleQuestionField {
  float: left;
  width: 90%;
}

.qaRemoveIcon {
  /* float: left; */
  margin-top: 0.6rem;
  margin-left: 1rem;
  margin-right: 0.2rem;
}

.flashQuizAnswerButton {
  margin: 1rem 0rem 0rem 2rem !important;
}

.subModuleAnswerDiv {
  margin-left: 2rem;
}

.subModuleAnswerDiv2 {
  width: 100%;
}

.subModuleAnswerNumber {
  float: left;
  margin-right: 1rem;
  margin-top: 0.9rem;
}

.subModuleAnswerField {
  /* float: left; */
  width: 95%;
  margin-top: 0.8rem;
}

.correctAnswer {
  font-size: 0.8rem;
  margin-left: 2rem !important;
  /* width: 100%; */
  /* margin-top: 3rem; */
}

div.ui.modal.modalTest {
  background-color: white;
  height: 50%;
  width: 60%;
  position: absolute;
  top: 0;
  margin: 10% 20%;
}

.quizModalButton {
  background-color: #1e87f0;
}

.supportingLanguage {
  font-size: 0.9rem;
  cursor: pointer;
  padding: 0.4rem 1rem;
  font-weight: 500;
  margin-right: 0.5rem;
}

.supportingLanguage:hover {
  background-color: #292929;
  border-radius: 5px;
  color: #ffffff;
}
.supportingLanguageActive {
  font-size: 0.9rem;
  cursor: pointer;
  color: #ffffff;
  padding: 0.4rem 1rem;
  font-weight: 500;
  background-color: #000000;
  border-radius: 5px;
  margin-right: 0.5rem;
}

.langErrorMessage {
  color: rgb(221, 0, 0);
  margin-left: 0.8rem !important;
  font-size: 0.75rem;
  font-weight: 500;
}

.dashboardCard {
  width: 30%;
  /* padding: 1rem; */
  background-color: white;
  border-radius: 6px;
  float: left;
  margin: 1.5%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}


.dashboardCardImageDiv {
  height: 10rem;
}

.dashboardCardImage {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  height: 10rem;
  object-fit: cover !important;
}

.dashboardCardContentDiv {
  padding: 0.8rem 1rem;
}

.fr_quiz_score{
  font-size: 0.8rem;
}
.quiz_score{
  font-size: 0.75rem;
}
.dashboardCardTitleDiv {
  padding: 0.4rem 1rem;
  height: 4.5rem;
  overflow: hidden;
  /* //overflow_added on June17 */
}

.dashboardCardTitle {
  font-size: 0.9rem;
  font-weight: 500;
}

.dashboardCardRibbon {
  height: 4rem;
  width: inherit;
  margin-left: 1rem;
}

.dashboardCardBottomDiv {
  border-top: solid rgb(226, 226, 226) 1px;
  padding: 1rem;
  font-size: 0.8rem;
  font-weight: bold;
  color: rgb(94, 94, 94);
}

.marquee {
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  /* position: absolute; */
  background-color: #00a9e0;
  width: 100%;
  color: white;
  font-size: 0.9rem;
  padding: 0.7rem 0rem;
  /* margin-bottom: 1rem; */
}


.marquee_ar {
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  background-color: #00a9e0;
  width: 100%;
  color: white;
  font-size: 0.9rem;
  padding: 0.7rem 0rem;
}

.marquee_ar span {
  display: inline-block;
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -moz-animation: marquee_ar 100s linear infinite;
  -webkit-animation: marquee_ar 100s linear infinite;
  animation: marquee_ar 100s linear infinite;
}

.marquee span {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 100s linear infinite;
}

.marquee2 span {
  animation-delay: 2.5s;
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

/* @keyframes marquee_ar {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(100%, 0) !important;
  }
} */

 
@-moz-keyframes marquee_ar {
  0% {
      -moz-transform: translateX(0);
  }
  100% {
      -moz-transform: translateX(100%);
  }
}

@-webkit-keyframes marquee_ar {
  0% {
      -webkit-transform: translateX(0);
  }
  100% {
      -webkit-transform: translateX(100%);
  }
}

@keyframes marquee_ar {
  0% {
      -moz-transform: translateX(0);
      -webkit-transform: translateX(0);
      transform: translateX(0);
  }
  100% {
      -moz-transform: translateX(100%);
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
  }
}

.marquee_span_text{
  margin-right: 5rem;
}

.marquee_span_text_ar{
  margin-left:5rem;
}

i.fas.announcementIcon {
  height: 0.3rem;
}

.tableButtonAnnouncement {
  width: 6rem;
  float: right;
}

.blueButton {
  background-color: #4397c4;
  /* float: right; */
  color: white;
  padding: 0.5rem 1rem;
  border:none !important;
  border-radius: 5px;
}

.blueButton:hover {
  background-color: #377a9e;
}

.adminExamQuestionButton {
  margin-top: 0.5rem;
}

.examQuestionDiv {
  /* padding: 0rem 1rem; */
}

.examQuestion {
  margin-top: 1rem;
  font-weight: 500;
}

.examMarksText {
  font-size: 0.8rem;
  margin: 0rem 0.5rem 0rem 1rem;
}

.examMarksText2 {
  font-size: 0.8rem;
  margin: 0rem 0.5rem 0rem 0.5rem;
}

.documentUpladDivs {
  /* float: left; */
}

.examResourceUploadBtn {
  margin-left: 2rem;
}

.examImageDiv {
  margin-top: 1rem;
  width: 100%;
  height: 4rem;
}

.examDocIconDiv {
  height: 4rem;
  width: 4rem;
  float: left;
  cursor: pointer;
  /* background-color: red; */
}

.examDocIcon {
  height: 3.5rem;
  width: auto;
}

.examDocIconUser {
  height: 4.5rem;
  width: auto;
  cursor: pointer;
  margin-top: 0.5rem;
}

.examDocIconUser:hover {
  transform: scale(1.05);
}

.examDocRemoveIcon {
  height: 1.2rem;
  width: auto;
  position: absolute;
  margin-left: -1.4rem;
  margin-top: -0.2rem;
}

.examDocRemoveIcon:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.resourceName {
  font-size: 0.7rem;
}

.examCard {
  width: 85%;
  background-color: white;
  padding: 1rem;
  border-radius: 10px;
  height: 7.5rem;
  margin: 0 auto;
  box-shadow: 4px 6px 4px -2px rgba(128, 128, 128, 0.349);
  /* cursor: pointer; */
  margin-bottom: 0rem;
  overflow: hidden !important;
  text-overflow: ellipsis;
  margin-top: 1.5rem;
}

.examCardPublishedDate {
  font-size: 0.8rem;
  font-style: italic;
  margin-top: -1rem !important;
}

.examCard:hover {
  box-shadow: 8px 8px 8px -2px rgba(128, 128, 128, 0.349);
  transform: scale(1.001);
}

.examCardTable {
  width: 100%;
  margin-top: 0.8rem;
}

.examCardTable td {
  width: 25%;
}

.examCardCol1 {
  width: 80%;
  float: left;
  height: 100%;
  overflow: auto !important;
  text-overflow: ellipsis;
}

.examCardCol1::-webkit-scrollbar {
  width: 3px;
  height: 4px;
  background-color: white;
}

.examCardCol1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.examCardCol1::-webkit-scrollbar-thumb {
  background-color: rgb(141, 141, 141);
}

.examCardCol1::-webkit-scrollbar-thumb:hover {
  background-color: #4397c4;
}

.examCardCol2 {
  width: 19%;
  float: right;
  border-left: solid 1px rgb(199, 199, 199);
  padding-left: 0.5rem;
  height: 100%;
}

.examCardCol2_ar {
  width: 19%;
  float: left;
  border-right: solid 1px rgb(199, 199, 199);
  padding-left: 0.5rem;
  height: 100%;
}

.examCardTitle {
  font-size: 1rem;
  font-weight: bold;
  color: rgb(43, 43, 43);
}

.examCardDescription {
  font-size: 15px !important;
}

.examCardImageDiv {
  width: fit-content;
  margin: 0 auto !important;
  margin-top: 0.5rem !important;
}

.examCardImage {
  height: 3rem;
  width: auto;
}

.blueButton2 {
  background-color: #4397c4;
  /* height: 3rem; */
  width: 8rem;
}

.examCardButtonDiv {
  width: fit-content;
  padding: 1rem 0rm;
  margin: 0 auto !important;
  margin-top: 2.2rem !important;
}

.blueButton2:hover {
  background-color: #2e6583;
}

.blueButton3 {
  background-color: #4397c4;
  /* height: 3rem; */
  width: 10rem;
}

.blueButton3:hover {
  background-color: #2e6583;
}

button.uk-button.uk-button-primary.admin-table-btn.blueButton3 {
  margin-right: 0rem !important;
}

.examQuestionTextArea {
  font-size: 0.9rem;
  margin-top: 1rem;
}

.examQuestionTextArea::-webkit-scrollbar {
  width: 3px;
  height: 0px;
  background-color: #c9c9c9;
}

.examQuestionTextArea::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: #dadada;
}

.examQuestionTextArea::-webkit-scrollbar-thumb {
  background-color: #edeff0;
}

.examQuestionTextArea::-webkit-scrollbar-thumb:hover {
  background-color: #9e9e9e;
}

.examQuestionText {
  font-size: 0.9rem;
  text-align: justify;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.reviewButtonExamSub {
  width: 6rem;
  background-color: #4397c4;
  color: white;
  float: right;
}

.reviewButtonExamSub:hover {
  background-color: #347091;
}

.editReviewButtonExamSub {
  width: 6rem;
  background-color: #ee395b;
  color: white;
  float: right;
}

.editReviewButtonExamSub:hover {
  background-color: #c5314c;
}

.userDetailsRightAligned {
  font-weight: normal;
}

.userDetailsLeftAligned {
  font-weight: normal;
}

.examSubmissionQDiv {
  margin: 2rem 0rem !important;
  /* white-space: pre-wrap;
  overflow-wrap: break-word; */
}

.commentTitle {
}

.commentMainDiv {
  margin-left: 1rem;
  margin-bottom: 1rem;
  width: 80%;
}

.commentMainDivAnswer {
  margin: 0rem 0rem 0rem auto;
  margin-right: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 70%;
  background-color: rgba(241, 241, 241, 0.596);
  padding: 0.4rem 1rem 1rem 1.5rem;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.commentMainDivAnswerUser {
  margin: 0rem 0rem 0rem auto;
  margin-right: 1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  width: 70%;
  background-color: rgb(247, 247, 247);
  padding: 0.4rem 1rem 0.5rem 1.5rem;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.commentUserReviewer {
  font-size: 0.85rem;
  font-weight: bold;
  color: rgb(43, 94, 153);
}

.commentUser {
  font-size: 0.85rem;
  font-weight: bold;
  color: rgb(202, 45, 79);
}

.commentDate {
  font-size: 0.7rem;
  font-weight: 600;
  color: rgb(177, 177, 177);
  margin-bottom: 0.3rem;
}

.commentText {
  font-size: 0.8rem;
  font-weight: 600;
  text-align: justify;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.commentButton {
  font-size: 0.7rem;
  font-weight: 600;
  color: rgb(177, 177, 177);
  cursor: pointer;
  /* float: right; */
  margin-left: 1.5rem;
  margin-right: -0.5rem;
}

.commentButtonRed {
  font-size: 0.7rem;
  font-weight: 600;
  color: rgb(189, 11, 11);
  cursor: pointer;
  /* float: right; */
  margin-left: 1.5rem;
  margin-right: -0.5rem;
}

.commentButtonRed:hover {
  color: rgb(133, 32, 32);
  text-decoration: underline;
}

.commentButtonBlue {
  font-size: 0.7rem;
  font-weight: 600;
  color: rgb(11, 109, 189);
  cursor: pointer;
  /* float: right; */
  margin-left: 1.5rem;
  margin-right: -0.5rem;
}

.commentButtonBlue:hover {
  color: rgb(7, 72, 124);
  text-decoration: underline;
}

.commentButton:hover {
  color: rgb(24, 24, 24);
  text-decoration: underline;
}

.newCommentBox {
  margin: 1rem 1rem 2rem 1rem;
  font-size: 0.8rem;
  font-weight: bold;
  color: rgb(78, 78, 78);
}

.newCommentBox2 {
  margin: 1rem 1rem 2rem 1rem;
  font-size: 0.8rem;
  font-weight: bold;
  color: rgb(78, 78, 78);
  width: 60%;
  /* float: right; */
  margin: 0rem 0rem 0rem auto;
}
.commentTextArea2 {
  margin-top: 0.3rem;
  font-weight: normal;
  width: 90%;
  font-size: 0.8rem;
}

.commentTextArea {
  margin-top: 0.3rem;
  font-weight: normal;
  width: 90%;
  font-size: 0.8rem;
}

.sendIcon {
  color: #4397c4;
  font-size: 1.2rem;
  margin-top: 1.2rem;
  margin-left: 1rem;
  cursor: pointer;
}

.sendIcon:hover {
  color: #27546d;
  transform: scale(1.1);
}

.sendIcon_ar {
  color: #4397c4;
  font-size: 1.2rem;
  margin-top: 1.2rem;
  margin-right: 1rem;
  cursor: pointer;
}

.sendIcon_ar:hover {
  color: #27546d;
  transform: scale(1.1);
}
.fa.fa-paper-plane.sendIcon_ar{
  -webkit-transform: rotate(260deg);
    -moz-transform: rotate(260deg);
    -ms-transform: rotate(260deg);
    -o-transform: rotate(260deg);
    transform: rotate(260deg);
}

.commentEditedText {
  font-size: 0.7rem;
  font-weight: 600;
  color: rgb(177, 177, 177);
  margin-bottom: 0.3rem;
  font-style: italic;
}

.examTitleEC {
  font-size: 1.5rem;
  margin: 2rem 0rem 0rem 0rem;
  font-weight: bold !important;
}

.examSubTitleEC {
  margin: 2rem 0rem 0rem 0rem;
  font-size: 1rem;
  font-weight: bold;
  font-style: italic;
}

.examDescriptionEC {
  margin: 1rem 0rem 3rem 0rem;
  font-weight: 500;
  text-align: justify;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.commentsSectionUser {
  background-color: white;
  border-radius: 10px;
  padding: 0rem 1rem;
}

.commentTitleUser {
  margin-left: -1.3rem;
}

.commentTitleDivUser {
  border-bottom: solid 1px rgba(196, 196, 196, 0.445);
}

.examCardIcon {
  height: 1.2rem;
  width: auto;
  margin-right: 0.3rem;
}

.examCardIcon_ar {
  height: 1.2rem;
  width: auto;
  margin-left: 0.3rem;
}

.examCategory {
  margin: 2rem 4.3rem;
  font-size: 1.2rem;
  font-weight: bold !important;
}
.examCategory_Arabic{
 margin-right: 3rem !important;
}


.examCategoryButton {
  margin: 10px;
  border-radius: 25px;
  border: solid 1px rgba(167, 167, 167, 0.692);
  background-color: #ffffff00;
  color: #161616;
  width: 7rem;
  padding: 0rem !important;
}

.examCategoryButtonActive {
  margin: 10px;
  border-radius: 25px;
  border: solid 1px rgba(167, 167, 167, 0.692);
  background-color: #1eabe0;
  color: rgb(255, 255, 255);
  width: 7rem;
  padding: 0rem !important;
}

.examCategoryButton:hover {
  color: #1eabe0;
}

.examCategoryButtonsDiv {
  margin-left: 2rem;
}

.saveGradeButton {
  padding: 0rem 0.5rem;
  font-size: 0.8rem;
  margin-left: 1rem;
}

.messageCustom {
  margin: 0rem 1rem !important;
}

.addAnswerCommentButton {
  font-size: 0.7rem;
  font-weight: 600;
  color: #00a9e0;
  cursor: pointer;
  float: right;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.addAnswerCommentButton1 {
  font-size: 0.85rem;
  font-weight: 600;
  color: white;
  cursor: pointer;
  float: right;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  background-color: #00a9e0;
  padding: 0.6rem 1rem;
  border-radius: 28px;
}

.addAnswerCommentButton:hover {
  text-decoration: underline;
}

.addAnswerCommentButton1:hover {
  text-decoration: underline;
}
.ratingAr > label{
  float: left !important;
}
.ratingsModalDiv {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.589) !important;
  z-index: 1000;
  overflow: hidden !important;
}

.ratingsModal {
  background-color: rgb(255, 255, 255) !important;
  margin: 0 auto !important;
  margin-top: 8% !important;
  padding: 1.6rem;
  border-radius: 15px;
  width: 22rem;
  text-align: center;
  font-weight: 500;
}

.ratingsModal1 {
  background-color: rgb(255, 255, 255) !important;
  margin: 0 auto !important;
  margin-top: 15% !important;
  padding: 1.6rem;
  border-radius: 15px;
  width: 22rem;
  text-align: center;
  font-weight: 500;
}

.ratingsModalStarsDiv {
  /* background-color: red; */
  /* height: 3rem; */
  width: fit-content;
  margin: 0 auto;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.ratingsModalRating {
  font-size: 1rem;
}

.submitFeedbackButton {
  /* background-color: #4397c4; */
  font-size: 0.8rem !important;
  width: 49%;
  margin-top: 1rem;
  margin-left: 3px;
}

.submitFeedbackButton1 {
  background-color: #d12424;
  font-size: 0.8rem !important;
  width: 49%;
  margin-top: 1rem;
  color: white;
}

.submitFeedbackButton1:hover {
  background-color: #961a1a;
  color: white;
}

.overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 1000; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.loginBannerBackground {
  /* padding: 1rem; */
  background-image: url(./assets/images/backgrounds/kms-image-ori.png) !important;
  background-blend-mode: color-burn;
  /* height: 30rem; */
  background-position: left bottom;
  background-size: contain;
  /* margin-bottom: -20px; */
  /* background-color: lawngreen; */
  /* backgroundBlendMode: "color-burn",
    height: "100%",
    backgroundPosition: "center",
    backgroundSize: '100%',
    width: "100%", */
  /* padding: 10px; */
}
.loginSubDiv {
  /* padding: 1rem; */
}

.loginNavBar {
  width: 100%;
}

.loginSection {
  width: 100%;
  /* height: 20rem; */
  background-image: url(./assets/images/backgrounds/kms-image-ori.png) !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 20% 30%;
}

.loginSectionSiteTitle{
  color: white;
  position: absolute;
  font-size: 44px;
  margin-top: 4.7rem;
}

.loginAnnouncementLine {
  width: 100%;
  background-color: #00a9e0;
  /* padding: 1rem; */
}

.loginBox {
  height: 19rem;
  width: 22rem;
  background-color: #00a9e0;
  margin: 0 auto;
  border-radius: 10px;
  padding: 20px 20px;
}


.loginBoxEmpty {
}

.loginBoxText {
  font-size: 0.9rem;
  color: white;
  /* position: absolute; */
  bottom: 0;
}

.forgotPwButton {
  color: #fabf1c;
  font-weight: bold;
  cursor: pointer;
}

.forgotPwButton:hover {
  color: white;
  font-weight: bold;
}

.loginYellowButton {
  background-color: #eaaa00;
  color: white;
  width: 9rem;
  border: none;
  box-shadow: 0 4px 2px -2px rgba(128, 128, 128, 0.349);
  /* margin-top: 1rem; */
}

.loginYellowButton:hover {
  background-color: #c99404;
  color: white;
  /* box-shadow: 0 4px 2px -2px rgba(128, 128, 128, 0.349); */
}

.linkText {
  color: rgb(0, 169, 224);
  cursor: pointer;
  margin-bottom: 2rem !important;
}

.linkText:hover {
  color: rgb(0, 124, 165);
}

.loginYellowButton:disabled {
  background-color: #c99404;
  color: rgb(182, 182, 182);
  /* box-shadow: 0 4px 2px -2px rgba(128, 128, 128, 0.349); */
}

.loginBoxTextDiv {
  margin-left: 0.8rem;
  /* margin-top: 2rem; */
}

.loginSpinner {
}

.loginSectionOne {
  /* height: 10rem; */
  padding: 1rem 1rem;
}

.loginSectionTwo {
  height: 16rem;
  width: 100%;
  background-image: url(../src/images/login22.png) !important;
  background-size: cover;
  background-repeat: no-repeat;
}

.image2Text {
  color: white;
  position: absolute;
  margin-top: 4rem;
  font-size: 30px;
  right: 0;
  margin-right: 7rem;
}

.loginSectionOneCol1 {
  width: 40%;
  /* background-color: rgba(4, 4, 46, 0.123); */
  height: 100%;
  float: left;
  text-align: center;
}

.loginSectionOneCol2 {
  width: 60%;
  height: 100%;
  float: left;
  padding: 2rem 0rem;
}

.announcementBox {
  /* height: 30rem; */
  height: 28rem;

  width: 25rem;
  background-color: #00a9e0;
  margin: 0 auto;
  border-radius: 10px;
}


li.slide {
  height: 100% !important;
  border-radius: 10px;
}

li.slide.selected {
  height: 100% !important;
  border-radius: 10px;
}

div.slider-wrapper.axis-horizontal {
  height: 100%;
  border-radius: 10px;
}

ul.slider.animated {
  height: 100%;
}

div.carousel.carousel-slider {
  height: 100%;
}

div.carousel-root.announcementCarousel {
  height: 100%;
}

div.carouselDiv {
  background-color: #00a9e0;
  height: 100% !important;
  border-radius: 10px !important;
  padding: 1.5rem 3.5rem;
  text-align: justify;
  font-size: 0.85rem;
  /* border: 1px solid !important; */
}

div.carouselDiv_ar {
  background-color: #00a9e0;
  height: 100% !important;
  border-radius: 10px !important;
  padding: 1rem 3.5rem;
  text-align: justify;
  font-size: 0.85rem;
  /* direction: ltr; */
 
}

.announcementCarousel li.slide.selected {
  border: none !important;
  height: 100% !important;
  border-radius: 10px;
}

.carousel .slide {
  background-color: #00a9e0 !important;
  color: white;
  border-radius: 10px;
}

.starFeedbackMan {
  height: 1.5rem;
  width: auto;
}

.starFeedbackMan1 {
  height: 2rem;
  width: auto;
}

.annYellowButton {
  background-color: #eaaa00;
  color: white;
  width: fit-content;
  border: none;
  box-shadow: 0 4px 2px -2px rgba(128, 128, 128, 0.349);
}

.annYellowButton:focus {
  background-color: #eaaa00;
  color: white;
  width: fit-content;
  border: none;
  box-shadow: 0 4px 2px -2px rgba(128, 128, 128, 0.349);
}

.annYellowButton:hover {
  background-color: #b88702;
  color: white;
  width: fit-content;
  border: none;
  box-shadow: 0 4px 2px -2px rgba(128, 128, 128, 0.349);
}

.annTitleDiv {
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  /* background-color: red; */
  height: 25%;
  padding: auto;
  word-wrap: break-word !important;
}
.annTitleDiv_ar {
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  /* direction: rtl; */
  height: 25%;
  padding: auto;
  word-wrap: break-word !important;
}

.annDescDiv {
  font-size: 0.9rem;
  /* background-color: yellowgreen; */
  height: 53%;
  overflow: auto !important;
  text-align: center;
  padding: 5px;
  margin-top: 15px;
}

.annExpDateDiv {
  font-size: 0.9rem;
  /* background-color: yellowgreen; */
  height: 5%;
  text-align: center;
  padding: auto;
}
.annExpDateDiv_ar {
  font-size: 0.9rem;
  height: 5%;
 /* display: flex; */
  /* text-align: right; */
  text-align: center;
  /* direction: ltr !important; */
  word-wrap: break-word !important;
}

.annDescDiv::-webkit-scrollbar {
  width: 1px;
  height: 0px;
  background-color: #00a9e0;
}

.annDescDiv::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: #00a9e0;
}

.annDescDiv::-webkit-scrollbar-thumb {
  background-color: #00a9e0;
}

.annDescDiv::-webkit-scrollbar-thumb:hover {
  background-color: #00a9e0;
}
.annBtnDiv {
  /* position: absolute;
  left: 29%;
  bottom: 0;  */
  /* background-color: rgb(23, 105, 98); */

  padding-top: 1rem;
  /* height: 23%; */
}

.carousel.carousel-slider .control-arrow {
  /* top: 48% !important;
  height: 22px;
  width: 22px;
  border-radius: 21px;
  background-color: #EAAA00 !important;
  opacity: 0.8 !important;
  background-image: url(./images/nextAnnouncement.png) !important;
  margin: 2.3rem; */

  background-color: #eaac00d8 !important;
  height: 42px;
  width: 42px;
  border-radius: 21px;
  top: 45% !important;
  margin: 0rem 0.5rem;
}

button.control-arrow.control-next::before {
  /* background-color: red; */
  /* height: 5px; */
  /* width: auto; */
  /* margin-top: -35px !important; */
}

.justifiedText {
  text-align: justify !important;
}

.fade_card p {
  position: relative;
}

.fade_card .uk-card-body {
  position: relative;
}

.fade_bottom {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.06235997817095584) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.dashboardUserMain {
  background-color: rgb(248, 248, 248);
  padding: 1rem;
  border-radius: 8px;
}

.userBox {
  /* background-color: #24af52d5; */
  background-color: #105583c9;
  padding: 1rem;
  color: white !important;
  /* border-radius: 8px; */
  margin: 0 auto;
  border-radius: 5px;

}

.userBox2 {
  background-color: #e2ad1cbd;
  /* background-color: #105583c9; */
  padding: 1rem;
  color: white !important;
  /* border-radius: 8px; */
  margin: 0 auto;
  border-radius: 5px;

}

.circle {
  padding: 1rem;
  background-color: rgba(41, 41, 41, 0.233);
  width: fit-content;
  border-radius: 100%;
  margin: 0 auto;
}


.fitCenterDiv {
  width: fit-content;
  margin: 0 auto;
}

.smallUserBox {
  background-color: #3daed4bd;
  padding: 1rem;
  padding-bottom: 1.5rem;
  color: white !important;
  text-align: right !important;
  border-radius: 5px;
}

.userDownloadButtonDiv {
  padding-top: 2.5rem;
}

.userDownloadButton {
  background-color: #4397c4;
  color: white;
  font-weight: 500;
}

.userDownloadButton:hover {
  background-color: #377a9e;
  color: white;
}

.paddinglessTd {
  vertical-align: top;
}

/* .userTag {
  width: 10%;
  padding: 10px;
  background-color: turquoise;
} */

.learningBox1 {
  background-color: rgb(255, 255, 255);
  padding: 1.5rem;
  text-align: center;
  width: 60%;
  margin: 0 auto;
  box-shadow: 5px 6px 2px -2px rgba(128, 128, 128, 0.247);
  border: thin rgba(211, 211, 211, 0.281) solid;
  border-radius: 5px;
}

.learningBox2 {
  background-color: rgb(255, 255, 255);
  padding: 2rem 4rem;
  text-align: center;
  width: fit-content;
  /* margin: 0 auto; */
  box-shadow: 5px 6px 2px -2px rgba(128, 128, 128, 0.247);
  border: thin rgba(211, 211, 211, 0.281) solid;
  border-radius: 5px;
  margin: 0 auto;
  margin-top: 1.5rem;

}

.sblBox1 {
  /* background-color: rgb(67, 178, 212); */
  padding: 1rem;
  width: 70%;
  margin: 0 auto;
  height: max-content !important;
  border-radius: 5px;
}

.sblBox2 {
  /* background-color: rgb(46, 140, 168); */
  padding: 1rem;
  /* width: fit-content; */
  margin: 0 auto;
  border-radius: 5px;

}

.sblBox3 {
  /* background-color:  rgb(27, 113, 139); */
  padding: 1rem;
  width: 70%;
  margin: 0 auto;
  color: white;
  /* height: 5.1rem; */
  border-radius: 5px;

}

.ratingBox1 {
  background-color: rgb(212, 137, 94);
  padding: 1rem;
  width: 100%;
  margin: 0 auto;
  height: max-content !important;
  border-radius: 5px;

}

.ratingBox2 {
  /* background-color: ; */
  text-align: center;
  width: 100%;
  /* margin: 0 auto; */
  /* box-shadow: 5px 6px 2px -2px rgba(128, 128, 128, 0.247); */
  border-left: thin rgba(211, 211, 211, 0.281) solid;
  border-radius: 5px;
  margin: 0 auto;
  /* margin-top: 1.5rem; */
  padding: 0.8rem 0rem;

}

.customAccordion {
  padding: 0.8rem 0.5rem;
  background-color: rgb(214, 214, 214);
  font-size: 0.9rem;
  cursor: pointer;
  margin-top: 1rem;
  color: black;
  border-radius: 2px;
}

.customAccordion2 {
  padding: 0.8rem 0.5rem;
  background-color: rgb(214, 214, 214);
  font-size: 0.8rem;
  cursor: pointer;
  color: black;
  border-radius: 2px;
}

.customAccordion:hover {
  padding: 0.8rem 0.5rem;
  background-color: rgb(168, 168, 168);
  font-size: 0.9rem;
  cursor: pointer;
}

.customAccordionContent {
  padding: 1rem;

}

.statTableTd1 {
  padding: 1rem;
  width: 25%;
}

.statTableTd2 {
  padding: 1rem;
  width: 50%;
}

.statCard {
  /* width: 100%; */
  background-color: rgb(255, 255, 255);
  padding: 0.5rem;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: 9rem;
  /* color: rgb(85, 85, 85); */
  color: rgb(255, 255, 255);
  font-weight: 100;
  letter-spacing: 1px;
}

.statCardLargeNumber {
  font-size: 4rem;
  font-weight: 400;
}

.statCardLargeNumber2 {
  font-size: 3rem;
  font-weight: 400;
}

.statCardLargeNumber3 {
  font-size: 3rem;
  font-weight: 400;
}

.statCardLabel {
  font-size: 0.95rem;
  padding: 0.5rem 0.2rem;
  text-align: center;
}

.statCard2 {
  /* width: 100%; */
  background-color: rgb(126, 158, 160);
  padding: 0.8rem;
  border-radius: 5px;
  text-align: center;
  box-shadow: 5px 6px 2px -2px rgba(128, 128, 128, 0.247);
  min-height: 8rem;
  color: white
}

.statCardLabel2 {
  font-size: 1rem;
  padding: 0.5rem 0.2rem;
  text-align: left;
  font-weight: 500;
}

.statCardList {
  text-align: left;
  font-size: 0.9rem;
}

.statCard3 {
  /* width: 100%; */
  background-color: rgb(163, 108, 104);
  padding: 1rem;
  border-radius: 5px;
  text-align: center;
  box-shadow: 5px 6px 2px -2px rgba(128, 128, 128, 0.247);
  /* height: 8rem; */
  color: white

}

.statCard4 {
  /* width: 100%; */
  background-color: rgb(255, 255, 255);
  padding: 1rem;
  border-radius: 5px;
  text-align: center;
  box-shadow: 5px 6px 2px -2px rgba(128, 128, 128, 0.247);
  /* height: 8rem; */
}

.statButtons {
  background-color: rgb(255, 255, 255);
  color: rgb(53, 53, 53);
  border: thin solid rgba(185, 185, 185, 0.555);

  font-weight: 300;
  margin: 0rem 0.2rem;
  width: fit-content;
  padding: 0.5rem 0.5rem;
  font-size: 0.9rem;
  border-radius: 8px;
  float: left;
  cursor: pointer;
}

.statButtons:hover {
  background-color: rgb(71, 71, 71);
  color: rgb(255, 255, 255);
}

.statButtonsClicked {
  background-color: rgb(41, 41, 41);
  color: rgb(255, 255, 255);

  font-weight: 300;
  margin: 0rem 0.2rem;
  width: fit-content;
  padding: 0.5rem 0.5rem;
  font-size: 0.9rem;
  border-radius: 8px;
  float: left;
  cursor: pointer;

}

.filterStatButton {
  background-color: rgb(66, 134, 173);
  color: rgb(255, 255, 255);
  width: max-content;
  font-weight: 400;
  padding: 0.4rem 0.8rem;
  font-size: 0.9rem;
  border-radius: 8px;
  float: left;
  cursor: pointer;
  text-align: center;
}

.filterStatButton:hover {
  background-color:rgb(32, 67, 87);
  color: rgb(255, 255, 255);

}


/* CK Editor Alt Form Styles */

div.ck.ck-labeled-input input {
  width: 35rem;
   

}

.statTitleMain {
  background-color: #0e1f3f;
  width: fit-content;
  padding: 0.5rem 3rem 0.5rem 2rem;
  border-top-right-radius: 60px;
  border-bottom-right-radius: 60px;
  margin-left: -1rem;
  color: rgb(236, 236, 236);
}

.video.video {
  background-color: #383838 !important;
}

.data-tip-custom-styles {
  background-color: #16667a !important;
  max-width: 22rem !important;
  padding: 1rem !important;
  font-size: 0.95rem !important;
  font-weight: 500;
  box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.5);
  opacity: 0.9 !important;
  line-height: 1.6rem !important;
  text-align: center !important;
}

/* .homeSearchDiv {
  position: absolute;
  margin-top: 5.5rem;
  background-color: rgba(53, 53, 53, 0.795);
  padding: 0.2rem 0rem 0.5rem 0.5rem;
}

.homeSearchInput {
  margin-right: 0.8rem;
  width: 13rem;
  background-color: rgba(53, 53, 53, 0);
  border: none !important;
  color: white;
  font-size: 0.9rem;
  padding: 0.3rem;
} */

.homeSearchDiv2 {
  background-color: rgb(255, 255, 255);
  border-radius: 50px;
}

.homeSearchInput2 {
  margin-right: 0.8rem;
  margin-left: 0.4rem;
  width: 8rem;
  border: none !important;
  color: rgb(0, 0, 0);
  font-size: 0.85rem;
  padding: 0.3rem;
  border-radius: 50px;
  float: left;
}

.homeSearchInput2:focus {
  outline: none !important;
}

.homeSearchIson {
  background-color: #1eabe0;
  width: fit-content;
  float: left;
  padding: 0.3rem !important;
}

.searchResultsInput{
  margin-right: 0.8rem;
  width: 35rem;
  background-color: rgb(255, 255, 255);
  border: none !important;
  color: rgb(44, 44, 44);
  font-size: 0.9rem;
  padding: 0.7rem 0.8rem;
  border-radius: 50px;
  outline: none !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) ;

}

.searchResultsInput:focus{
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) ;
}

.searchResultsTitles {
  font-size: 0.9rem;
  font-weight: 500;
  color: black;
}

.searchResultsContents {
  font-size: 0.8rem;
}

.searchResultsMainDiv {
  background-color: rgb(247, 247, 247);
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border-radius: 5px;
  cursor: pointer;
}

.searchResultsMainDiv:hover {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) ;
}

.iconHover:hover {
  font-size: 1rem !important;
}

.iconHover2:hover {
  color: #0f7ae5
}

.iconHover2{
  margin-right: 1rem;
  cursor: pointer;
}

.iconHover2_ar:hover {
  color: #0f7ae5
}
.iconHover2_ar{
  margin-left: 1rem;
  cursor: pointer;
}

.searchResultCategory {
  background-color: rgb(41, 41, 41);
  padding: 0.8rem 1rem;
  /* width: fit-content; */
  color: white;
  border-radius: 5px;
}

.searchResultCategory:hover {
  background-color: rgb(25, 47, 68);
  padding: 0.8rem 1rem;
  /* width: fit-content; */
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.resStatTitle {
  color: #c45050;
}

.videoCaptionUser {
  font-size: 0.9rem !important;
  color: black;
  text-align: left;
}

.searchLoadingOverlay {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.712);
  position: fixed;
  top: 20;
  left: 0;
  height: 100%;
  z-index: 100;
}

.searchResultSpinner {
  margin: 0 auto ;
  /* background-color: red; */
  width: fit-content;
  margin-top: 20% !important;
}

.galleryImagesTitles {
  /* margin: 10px; */
  margin-bottom: -25px;
  border-radius: 25px;
  /* border: solid 1px rgba(167, 167, 167, 0.692); */
  background-color: #1EABE0;
  color: rgb(255, 255, 255);
  width: fit-content;
  padding: 0.4rem 1.5rem !important;
  text-align: center;
}

.galleryImagesTitles2 {
  /* margin: 10px; */
  margin-bottom: -32px;
  border-radius: 25px;
  /* border: solid 1px rgba(167, 167, 167, 0.692); */
  background-color: #1EABE0;
  color: rgb(255, 255, 255);
  width: fit-content;
  padding: 0.4rem 1.5rem !important;
  text-align: center;
}

.pdfIframe {
  width: 75% !important;
  background-color: black;
  border: 1px solid black;
  /* overflow: auto; */
}

.slidePdf {
  width: fit-content;
  margin: 0 auto;
  overflow: auto;
  /* height: 30rem; */
  /* height: 30rem; */
  
}

.slidePdf canvas {
  /* width: 100%; */
 /* max-width: none; */
   max-height:none;
  object-fit: cover !important;
}

.slideFoot1{
  background-color: #323232;
  width: 100%;
}


.slideFooterStyle1{
  display: flex !important;
  width: fit-content;
  margin: 0 auto;
  
}

.slideFooterStyle1 p{
  color: #ffffff;
}

.slideFooterStyle1 button {
  margin: 5px;
  color: #ffffff;
  background-color: #323232;
  padding-top: 10px;
}

.slideFooterStyle1 .prev:hover{
  color: lightslategrey;
}
.slideFooterStyle1 .next:hover{
  color: lightslategrey;
}




.pdfIframe1 {
   width: 75% !important;
   background-color: #202020;
  /*background-color: rgb(255, 0, 0);
  border: 1px solid black;
  overflow: auto; */
  margin-bottom: 1rem !important;
  border: 1px solid black;

}

.slidePdf1 {
 width: 100%;
 margin: 0 auto;
 overflow: auto;

   /* margin: 0 auto;
  overflow: auto; */
  
}

.slidePdf2 {
  width: fit-content;
  margin: 0 auto;
    /* margin: 0 auto;
   overflow: auto; */
   
 }

 .slidePdf3 {
  width: fit-content;
  margin: 0 auto;
    /* margin: 0 auto;
   overflow: auto; */
   
 }


 /* When slide scale = 1.0 */

.slidePdf2 canvas {
  /* width: 100%; */
    /* max-width: none; */
    min-height: 20rem;
   max-height:none;
  object-fit: contain !important;
}

 /* When slide scale > 1.0 */

 .slidePdf3 canvas {
  /* width: 100%; */
    max-width: none;
    min-height: 20rem;
   max-height:none;
  object-fit: cover !important;
  object-position: center;
}



.slidePdf1::-webkit-scrollbar {
  width: 1px;
  height: 4px;
  background-color: #3d3d3d;
}

.slidePdf1::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: #3d3d3d;
}

.slidePdf1::-webkit-scrollbar-thumb {
  background-color: #77bada;
}

.slidePdf1::-webkit-scrollbar-thumb:hover {
  background-color: #0165b8;
}

video.video1 {
  object-fit: contain !important;
  height: 100%;
}


#galleryVideoCardDiv{
  /* position:fixed; */
  /* margin:0; */
  width:fit-content;
  height:190px !important;
  background-color: rgb(51, 51, 51);
  /* border: 1px solid red; */
  /* overflow: ; */
}

#galleryVideoCardDiv video{
  /* position: absolute; */
  width: fit-content !important;
  height: 100% !important;
  /* background: url('//demosthenes.info/assets/images/polina.jpg') no-repeat; */
  /* background-size: contain; */
  /* object-fit: contain !important; */
  margin: 0 auto;
  transition: 1s opacity;
  z-index:-1;
}  

.modaleCancelButton {
  background-color: #e0e1e2;

  color: rgba(0,0,0,.6);
  font-size: 1rem !important;
  width: 49%;
  margin-top: 1rem;
  margin-left: 3px;
}

.modaleCancelButton:hover{
  background-color: #cacbcd;
  color: rgba(0,0,0,.6);
  border-color: #e0e1e2;
}

.modaleConfirmButton {
  background-color: #d12424;
  font-size: 1rem !important;
  width: 49%;
  margin-top: 1rem;
  color: white;
}

.modaleConfirmButton:hover {
  background-color: #961a1a;
  color: white;
}

.adminSectionTitles {
  font-size: 1.8rem;
}

.adminSectionTableTitles th {
  color: rgb(44, 44, 44) !important;
  font-weight: 600;
}

.adminSectionTextFieldLabel {
  color: black;
}

.uk-form-label {
  color: black;
  font-weight: 500;
}

.adminSectionOtherButtons1 {
  /* background-color: rgb(83, 136, 83) !important; */
  background-color:#9b2333 !important;
  color: white !important;
}

.adminSectionOtherButtons1:hover {
  background-color:#cc364a !important;
  color: white !important;
}

.adminSectionOtherButtons2 {
  background-color: rgb(83, 136, 83) !important;
  color: white !important;
}

.adminSectionOtherButtons2:hover {
  background-color: rgb(123, 190, 123) !important;
  color: white !important;
}

/* scrollable - editor */

.ck-rounded-corners .ck.ck-editor__editable:not(.ck-editor__nested-editable), .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-rounded-corners{
  max-height: 30rem;
  overflow: auto;
}


.ck-rounded-corners .ck.ck-editor__editable:not(.ck-editor__nested-editable), .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-rounded-corners::-webkit-scrollbar {
  width: 7px;
  height: 4px;
  background-color: #547985;
}

.ck-rounded-corners .ck.ck-editor__editable:not(.ck-editor__nested-editable), .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-rounded-corners::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: #dbdbdb;
}

.ck-rounded-corners .ck.ck-editor__editable:not(.ck-editor__nested-editable), .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-rounded-corners::-webkit-scrollbar-thumb {
  background-color: #5f9cb3;
  border-radius: 5px;
}

.ck-rounded-corners .ck.ck-editor__editable:not(.ck-editor__nested-editable), .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-rounded-corners::-webkit-scrollbar-thumb:hover {
  background-color: #4b8499;
}

.sblListSpinner {
  margin-left: 4rem;
}
.sblListSpinner_Arabic {
  margin-right: 3rem;
}

.announcementBoxSpinner{
  margin-top: 12rem;
}

div.uk-form-label.examQuestionText.examCategory {
  margin-bottom: 1.5rem !important;
}

.examCatPending {
  color: rgb(47, 113, 189);
}

.examCatOngoing {
  color: rgb(51, 185, 118);
}

div.examCategoryDivider {
  margin-top: 3rem !important;
  border-bottom: 1px solid rgba(150, 150, 150, 0.664);
}

.arabic p{
  /* font-family: "Adobe Arabic" !important; */
  /* direction: rtl; */
  font-size: 12px !important;
}
.other_language{
  /* font-size: 8px !important; */
}
/* 
.ck-style .image.image_resized {
  max-width: 100%;
  display: block;
  box-sizing: border-box;
}

.ck-style .image.image_resized img {
  width: 100%;
}

.ck-style .image.image_resized > figcaption {
  display: block;
}

.ck-style .image-style-side {
  float: right;
  margin-left: var(--ck-image-style-spacing);
  max-width: 50%;
}

.ck-style .image-style-align-left {
  float: left;
  margin-right: var(--ck-image-style-spacing);
}

.ck-style .image-style-align-center {
  margin-left: auto;
  margin-right: auto;
}

.ck-style .image-style-align-right {
  float: right;
  margin-left: var(--ck-image-style-spacing);
}

.ck-style .image {
  display: table;
  clear: both;
  text-align: center;
  margin: 1em auto;
}

.ck-style .image img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  min-width: 50px;
}

.ck-style .image > figcaption {
  display: table-caption;
  caption-side: bottom;
  word-break: break-word;
  color: hsl(0, 0%, 20%);
  background-color: hsl(0, 0%, 97%);
  padding: .6em;
  font-size: .75em;
  outline-offset: -1px;
}

.ck-style .marker-yellow {
  background-color: var(--ck-highlight-marker-yellow);
}

.ck-style .marker-green {
  background-color: var(--ck-highlight-marker-green);
}

.ck-style .marker-pink {
  background-color: var(--ck-highlight-marker-pink);
}

.ck-style .marker-blue {
  background-color: var(--ck-highlight-marker-blue);
}

.ck-style .pen-red {
  color: var(--ck-highlight-pen-red);
  background-color: transparent;
}

.ck-style .pen-green {
  color: var(--ck-highlight-pen-green);
  background-color: transparent;
}

.ck-style .text-tiny {
  font-size: .7em;
}

.ck-style .text-small {
  font-size: .85em;
}

.ck-style .text-big {
  font-size: 1.4em;
}

.ck-style .text-huge {
  font-size: 1.8em;
}

.ck-style blockquote {
  overflow: hidden;
  padding-right: 1.5em;
  padding-left: 1.5em;
  margin-left: 0;
  margin-right: 0;
  font-style: italic;
  border-left: solid 5px hsl(0, 0%, 80%);
}

.ck-style[dir="rtl"] blockquote {
  border-left: 0;
  border-right: solid 5px hsl(0, 0%, 80%);
}

.ck-style code {
  background-color: hsla(0, 0%, 78%, 0.3);
  padding: .15em;
  border-radius: 2px;
}

.ck-style .table {
  margin: 1em auto;
  display: table;
}

.ck-style .table table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  border: 1px double hsl(0, 0%, 70%);
}

.ck-style .table table td,
.ck-style .table table th {
  min-width: 2em;
  padding: .4em;
  border: 1px solid hsl(0, 0%, 75%);
}

.ck-style .table table th {
  font-weight: bold;
  background: hsla(0, 0%, 0%, 5%);
}

.ck-style[dir="rtl"] .table th {
  text-align: right;
}

.ck-style[dir="ltr"] .table th {
  text-align: left;
}

.ck-style .page-break {
  position: relative;
  clear: both;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ck-style .page-break::after {
  content: '';
  position: absolute;
  border-bottom: 2px dashed hsl(0, 0%, 77%);
  width: 100%;
}

.ck-style .page-break__label {
  position: relative;
  z-index: 1;
  padding: .3em .6em;
  display: block;
  text-transform: uppercase;
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
  font-size: 0.75em;
  font-weight: bold;
  color: hsl(0, 0%, 20%);
  background: hsl(0, 0%, 100%);
  box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ck-style .media {
  clear: both;
  margin: 1em 0;
  display: block;
  min-width: 15em;
}

.ck-style .todo-list {
  list-style: none;
}

.ck-style .todo-list li {
  margin-bottom: 5px;
}

.ck-style .todo-list li .todo-list {
  margin-top: 5px;
}

.ck-style .todo-list .todo-list__label > input {
  -webkit-appearance: none;
  display: inline-block;
  position: relative;
  width: var(--ck-todo-list-checkmark-size);
  height: var(--ck-todo-list-checkmark-size);
  vertical-align: middle;
  border: 0;
  left: -25px;
  margin-right: -15px;
  right: 0;
  margin-left: 0;
}

.ck-style .todo-list .todo-list__label > input::before {
  display: block;
  position: absolute;
  box-sizing: border-box;
  content: '';
  width: 100%;
  height: 100%;
  border: 1px solid hsl(0, 0%, 20%);
  border-radius: 2px;
  transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background, 250ms ease-in-out border;
}

.ck-style .todo-list .todo-list__label > input::after {
  display: block;
  position: absolute;
  box-sizing: content-box;
  pointer-events: none;
  content: '';
  left: calc( var(--ck-todo-list-checkmark-size) / 3 );
  top: calc( var(--ck-todo-list-checkmark-size) / 5.3 );
  width: calc( var(--ck-todo-list-checkmark-size) / 5.3 );
  height: calc( var(--ck-todo-list-checkmark-size) / 2.6 );
  border-style: solid;
  border-color: transparent;
  border-width: 0 calc( var(--ck-todo-list-checkmark-size) / 8 ) calc( var(--ck-todo-list-checkmark-size) / 8 ) 0;
  transform: rotate(45deg);
}

.ck-style .todo-list .todo-list__label > input[checked]::before {
  background: hsl(126, 64%, 41%);
  border-color: hsl(126, 64%, 41%);
}

.ck-style .todo-list .todo-list__label > input[checked]::after {
  border-color: hsl(0, 0%, 100%);
}

.ck-style .todo-list .todo-list__label .todo-list__label__description {
  vertical-align: middle;
}

.ck-style .raw-html-embed {
  margin: 1em auto;
  min-width: 15em;
  font-style: normal;
}

.ck-style hr {
  margin: 15px 0;
  height: 4px;
  background: hsl(0, 0%, 87%);
  border: 0;
}

.ck-style pre {
  padding: 1em;
  color: hsl(0, 0%, 20.8%);
  background: hsla(0, 0%, 78%, 0.3);
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  text-align: left;
  direction: ltr;
  tab-size: 4;
  white-space: pre-wrap;
  font-style: normal;
  min-width: 200px;
}

.ck-style pre code {
  background: unset;
  padding: 0;
  border-radius: 0;
}

.ck-style .mention {
  background: var(--ck-color-mention-background);
  color: var(--ck-color-mention-text);
}
@media print {
  .ck-style .page-break {
      padding: 0;
  }
  .ck-style .page-break::after {
      display: none;
  }
}
.ck-style .image-style-align-center,
.ck-style .image-style-align-left,
.ck-style .image-style-align-right,
.ck-style .image-style-side {
  max-width: 50%;
} */

/* .arabic div.ui.dropdown.languageSwitcherLogin {
  text-align: center;
  position: absolute;
  margin-left: -4.5rem !important;
  margin-top: -0.1rem !important;
  margin-right:-60px !important;
}
.arabic .uk-navbar-nav {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-right: 56px;
} */

.sbl_para{
  width: 95%;
  
}

.sbl_para_sp_fr{
  width: 105%;
 
}
/* commencing Arabic styles */

.sideNav img{
  margin-right: -3px;
}
.submodule_min{
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 1;
}

.dashboardCard_Arabic {
  width: 30%;
  /* padding: 1rem; */
  background-color: white;
  border-radius: 6px;
  float: right;
  margin: 1.5%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

.carousel_Arabic .slider.animated{
  direction: ltr;
  transition-duration: 350ms;
}

.carousel_Arabic button.control-arrow.control-next{
 margin-right: 22rem !important;
}

.carousel_Arabic .carousel.carousel-slider button.control-prev.control-arrow{
  right: 0 !important;
  
} 
.carousel_Arabic .carousel.carousel-slider .control-arrow.control-prev{
  transform: rotate(180deg);
}
.carousel_Arabic .carousel.carousel-slider .control-arrow.control-next{
  transform: rotate(180deg);
}


/* .carousel_Arabic button.control-prev.control-arrow{
  border-left: 0px solid #fff;
}

.carousel_Arabic button.control-next.control-arrow{
  border-right: 0px solid #fff;
} */

/* .carousel_Arabic button.control-next.control-arrow:before{
  border-left: 8px solid #fff;
} */

.titleFaq_AR {
  display: flex;
  position: relative;
  padding-left: 2.3em;
  margin-right: -0.5rem;
}

.titleFaq_AR:after {
  position: absolute;
  left: 0;
}

.titleFaq_AR strong {
  display: table-cell;
  padding-right: 5px;
}

.Rowcommon_AR {
  display: flex;
  padding-left: 2.3em;
  margin-right: -0.5rem;

}
.Rowcommon_AR div{
  padding-right: 5px;
}
.flashQuizAnswerDiv_Ar {
  margin-top: 0.4rem;
  margin-left: 0.7rem;
  display: block;
  margin-left: -4px;
}

.flashQuizAnswerDiv_after_submission_Ar{
  margin-top: 0.4rem;
  margin-right: 0.7rem;
  margin-right: 1.2rem;
}
.flashQuizSubmitButton_Ar {
  float: left !important;
  margin: 0rem;
  margin-bottom: 1rem;
  background-color: rgb(187, 187, 187);
  font-size: 0.9rem;
  width: fit-content !important;
}

.flashQuizSubmitButton_Ar:hover {
  color: white;
  background-color: #0f7ae5;
}
.danger_alert_Arabic{
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    height:"25";
    border-radius: 2px;
    font-size: 15px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.success_alert_Arabic{
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  border-radius: 2px;
  height:"25";
  font-size: 15px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.Arabic_box{
  padding: 0.78571429em 1em 0.78571429em 2.1em !important;
  text-align: right !important;
  width: 380px !important;
}
.contact_text{
  color: #151F6D;
}
.contact_text_Arabic{
  color: #151F6D;
  padding-right: 5px;
}
.Arabic_contact_para{
  display: flex;
  /* margin-right: 30px; */

}
.Other_contact_para{
  /* display:"flex"; */
  padding-left:"45px";
}

.close_sitemap{
  cursor: pointer;
  height: auto; 
  width: 1rem;
  float: right;
  margin-top: -55px;
}
.close_sitemap_ar{
  cursor: pointer;
  height: auto; 
  width: 1rem;  
  float: right;
}

.leftFloat{
  float: left;
}
.certifi-compelte-course_ar{
/* direction: ltr; */
/* padding-left: -17%; */
margin-top: -3.0%;
padding-right: 6.9rem;
/* margin-left: 153px; */

}

.certifi-compelte-Date_ar{
  font-size: 17px !important;
  margin-top: 17%;
  padding-left: 40.9rem;
  direction: ltr;
  /* margin-right: 71.66%; */
}
.updateStyle{
  padding-left: 2px !important;
}
.incorrect_cred_styles{
  font-size: 12px;
}
.dont_hv_ac_style_fr{
  font-size: 14px !important;
}
.examBanner{
  padding-left: 1rem;
  width: 85%;
  padding-top: 10px;

}
.examBanner_ar{
  padding-left: 1rem;
  width: 85%;
  padding-top: 22px;
}
.newsBanner{
  padding-top:10px ;
}
.commentBox_ar{
  margin-right: 1.2rem;
}
/* .singleResourceStyle p span,  h4 span,  h5 span,  h6 span,  h1 span, h2 span,  h3 span{
  background-color:#edeff0 !important;
}
.singleResourceStyle li span{
  background-color:#edeff0 !important;
} */
/* .singleResourceStyle a span{
  background-color:#edeff0 !important;
} */

.inner{
  /* background: $main; */
  height: 48px;
  width: 48px;
  margin:auto;
  /* position: relative; */
  top:6px;
  border-radius: 50%;
}

a.SBL_tab_style{
  /* padding-top: 0px !important; */
  line-height:.9;
  width:120px;
  padding-top: 1px !important;
  font-size: 15px !important;
}
.checkBoxStyle{
  top: -2px !important
}
/* -webkit-transform: scaleX(-1);
 transform: scaleX(-1); */

 .disableLanguageVersionDiv {
   margin-top: 0.8rem;
   margin-bottom: 0.5rem;
   color: #1E87F0;
   font-weight: bold;
 }

 .disableLanguageVersionDiv2 {
  margin-top: 0.8rem;
   margin-bottom: 0.5rem;
   color: #1E87F0;
   font-weight: bold;
}


.container_arabic_c {
  position: relative;
  text-align: center;
  color: black;
}

.container_Others_c {
  position: relative;
  text-align: left;
  color: black;
}

.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
}

.top-left {
  position: absolute;
  top: 8px;
  left: 16px;
}

.top-right {
  position: absolute;
  top: 15.6rem;
  right: 8.3rem;
  font-size: 24px;
}

.top-right_others {
  position: absolute;
  top: 15.55rem;
  left: 10.1rem;
  font-size: 24px;
}

.top-right_org {
  position: absolute;
  top: 19.8rem;
  right: 8.3rem;
  font-size: 24px;
}
.top-right_date{
  position: absolute;
  top: 32.3rem;
  left: 46.4rem;
  font-size: 17px;
  direction: ltr;

}

.top-left_org {
  position: absolute;
  top: 19.7rem;
  left: 10.1rem;
  font-size: 24px;
}

.top-left_org_sp {
  position: absolute;
  top: 19.78rem;
  left: 10.1rem;
  font-size: 24px;
}
.top-left_date{
  position: absolute;
  top: 32.2rem;
  right: 43.7rem;
  font-size: 17px;
  direction: ltr;

}
.top-left_date_sp{
  position: absolute;
  top: 32.28rem;
  right: 41.2rem;
  font-size: 17px;
  direction: ltr;

}
.top-left_date_en{
  position: absolute;
  top: 32.28rem;
  right: 42.4rem;
  font-size: 17px;
  direction: ltr;

}
/* //Below 15th december */

@media print {
  @page { 
    size: landscape; 
  }
}
@page {
  size: PDF;
}
/* //// Above 15 */

/* @media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
} */

.jobTitle_one_common{
  color: #000 !important;
}

.jobTitle_one_french{
  color: #000 !important;
  padding-top: 24px !important;
}
/* 3rd Feb 2022 */
.logo_Style{
  max-width: 340px !important;
  object-fit: cover !important;
}

/* .admin_logo_nav{
  max-width: px !important;
  object-fit: cover !important;
} */