:root {
    --core-color: #151F6D ;
    --secondary--yellow: #EAAA00;
    --secondary--purple: #84329B;
    --secondary--green: #008C95;
    --secondary--blue: #00A9E0;
    --secondary--idontknowwhatthefuck: #99D6EA;
  }
.admin-table-btn {
    padding: 0px 12px !important;
    font-size: 13px !important;
    box-shadow: 0px -2px 0px 0px #84848430 inset !important;
    margin-right: 10px !important;
}

.admin-side {
    width: 260px;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: var(--secondary--blue) !important;
    transition: 0.4s ease all;
    z-index: 1;
}

.loading-table {
    animation: glow 1.5s ease-in-out infinite;
    -webkit-animation: glow 1.5s ease-in-out infinite;
}

@-webkit-keyframes glow {
    0% { background-color: #fcfcfd; }
    30% { background-color: #dbdbda; }
    100% { background-color: #fcfcfd; }
}
@-moz-keyframes glow {
    0% { background-color: #fcfcfd; }
    30% { background-color: #dbdbda; }
    100% { background-color: #fcfcfd; }
}
@-ms-keyframes glow {
    0% { background-color: #fcfcfd; }
    30% { background-color: #dbdbda; }
    100% { background-color: #fcfcfd; }
}
@-o-keyframes glow {
    0% { background-color: #fcfcfd; }
    30% { background-color: #dbdbda; }
    100% { background-color: #fcfcfd; }
}
@keyframes glow {
    0% { background-color: #fcfcfd; }
    30% { background-color: #dbdbda; }
    100% { background-color: #fcfcfd; }
}

.uk-navbar-nav > li > a {
    color:#000;
}

.uk-navbar-nav > li.uk-active > a {
    color: #4bd964;
}

.uk-navbar-nav > li:hover > a, .uk-navbar-nav > li > a:focus, .uk-navbar-nav > li > a.uk-open {
    color:#000;
}

.ippf-margin-bottom {
    margin-bottom: 10px;
}

.text-area-height {
    min-height: 160px;
}
body {
    background-color: #ffffff !important;
}

.extra-top-padding {
    padding-top: 100px;
}

.black-logo {
    color: #00BCD4;
}

/* Section1 */
.s1q1 {
    background-color: #ffffff !important;
}



.outline-button {
    width: 100%;
}

.section-accordian {
    width: 100% !important;
}

.uk-button-success {
    background-color: var(--secondary--blue);

}

.toolbar-nav > li > a:after {
    background-color: var(--secondary--yellow);
}

.uk-button-success:hover, .uk-button-success:focus {
    background-color: var(--secondary--yellow);
}

.paper .far {
    color: var(--secondary--yellow);
}

.file-icon {
    background-color: var(--secondary--blue) !important;
}

.file-upload-group .icon{
    color: white !important;
}

.uk-button-success.buttonLogin{
    background-color: var(--secondary--yellow) !important;
}
/* .ui.positive.button, .ui.positive.buttons .button {
    background-color: var(--secondary--yellow) !important;
    color: #ffffff;
  } */

.ippf-logo { 
    /* max-width: 340px !important; */
    /* max-width: 200px !important; */
    /* May 10-2022 from 200px */
    max-width: 185px !important;

    object-fit: cover !important;
}

.adm-logo {
    background-color: #ffffff !important;
}

