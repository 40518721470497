@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);

fieldset, label { margin: 0; padding: 0; }
h1 { font-size: 1.5em; margin: 10px; }



.rating { 
    border: none;
    float: left;
  }
  
  .rating > input { display: none; } 
  .rating > label:before { 
    margin: 5px;
    font-size: 1.75em;
    font-family: FontAwesome;
    display: inline-block;
    content: "\f005";
    cursor: pointer;
    

  }
  
  .rating > .half:before { 
    content: "\f089";
    /* position: absolute; */
  }
  
  .rating > label { 
    color: #ddd; 
   float: right; 
  }
  
  /***** CSS Magic to Highlight Stars on Hover *****/
  
  .rating > input:checked ~ label, /* show gold star when clicked */
  .rating:not(:checked) > label:hover, /* hover current star */
  .rating:not(:checked) > label:hover ~ label { color: #FFD700;  } /* hover previous stars in list */
  
  .rating > input:checked + label:hover, /* hover current star when changing rating */
  .rating > input:checked ~ label:hover,
  .rating > label:hover ~ input:checked ~ label, /* lighten current selection */
  .rating > input:checked ~ label:hover ~ label { color: #FFED85;  } 