@import url(https://fonts.googleapis.com/css?family=Lato:400,700,400italic,700italic&subset=latin);
body, h1, h2, h3, h4, h5 {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif ;
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.admin-side {
  width: 260px;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: var(--secondary--blue) !important;
  transition: 0.4s ease all;
  z-index: 1;
}
.uk-navbar-nav > li > a {
  color:#000 !important;
}

.black-logo {
  color: #00BCD4 !important;
}

